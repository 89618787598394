import { useEffect, useState } from "react";
import BestsellersProductCard from "../BestsellersProductCard/BestsellersProductCard";
import BestsellersCategoryImage from "./components/BestsellersCategoryImage";
import { IProductWithOffers } from "types/IProduct";
import { useAppSelector } from "store/reduxHooks";
import ViewAllLink from "../BestsellersProfitBuyBlock/components/ViewAllLink";
import cn from "classnames";
import styles from "./styles.module.scss";
import getProductsByArrayIds from "utils/helpers/getProductsByArrayIds";
import SkeletonBestsellerCard from "components/UI/Skeleton/SkeletonBestsellerCard/SkeletonBestsellerCard";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";
import { ICategoryTab } from "types/ICategoryTab";
import getBestsellerProducts from "utils/helpers/getBestsellerProducts";
import { getErrorData } from "utils/getErrorData";

export interface ITab {
  title: string;
  products: IProductWithOffers[];
}

interface BestsellersCategoryBlockProps {
  title: string;
  imageUrl: string;
  imageUrlLargeDesktop: string;
  imagePosition: "left" | "right";
  tabs: ITab[];
  tabIds: number[][];
  linkForButton?: string;
  categoryTabs: ICategoryTab[]
  isInitialLoading: boolean;
}

const BestsellersCategoryBlock: React.FC<BestsellersCategoryBlockProps> = ({
  title,
  imageUrl,
  imagePosition,
  tabs,
  tabIds,
  imageUrlLargeDesktop,
  linkForButton,
  categoryTabs,
  isInitialLoading
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { deviceType } = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<IProductWithOffers[]>([]);
  const [loadedProducts, setLoadedProducts] = useState<{
    [key: number]: IProductWithOffers[];
  }>({});
  const [numberOfSkeletons, setNumberOfSkeletons] = useState(0);

  const currentImage = deviceType.isLargeDesktop
    ? imageUrlLargeDesktop
    : imageUrl;

  const fetchProducts = async (tabIndex: number) => {
    if (loadedProducts[tabIndex]) {
      setProducts(loadedProducts[tabIndex]);
      return;
    }

    setIsLoading(true);
    try {
      let products: IProductWithOffers[] = [];
      if (tabIds[tabIndex].length < 4) {
        const { products: bestsellerProducts } = await getBestsellerProducts(categoryTabs[tabIndex]);
        products = bestsellerProducts.slice(0, 4);
      } else {
        products = await getProductsByArrayIds(tabIds[tabIndex]);
      }
      const filteredProducts = products.filter(product => product.offers.length > 0);
      setProducts(filteredProducts);
      setLoadedProducts((prev) => ({ ...prev, [tabIndex]: filteredProducts }));
    } catch (error) {
      const errorMessage = getErrorData(error);
      // eslint-disable-next-line no-console
      console.error(errorMessage.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
    if (!loadedProducts[tabIndex]) {
      fetchProducts(tabIndex);
    } else {
      setProducts(loadedProducts[tabIndex]);
    }
  };

  useEffect(() => {
    if (tabs.length > 0) {
      const initialLoadedProducts: { [key: number]: IProductWithOffers[] } = {};
      tabs.forEach((tab, index) => {
        if (tab.products.length > 0) {
          initialLoadedProducts[index] = tab.products;
        }
      });
      setLoadedProducts(initialLoadedProducts);
      if (tabs[0].products.length > 0) {
        setProducts(tabs[0].products);
      }
    }
  }, [tabs]);

  const displayedProducts = window.matchMedia(
    "(min-width: 700px) and (max-width: 809px)"
  ).matches
    ? products.slice(0, 4)
    : deviceType.isTablet
      ? products.slice(0, 3)
      : products;

  useEffect(() => {
    const productsCount = tabIds[activeTab]?.length || 0;
    const skeletonsCount = deviceType.isTablet ? Math.min(productsCount, 3) : productsCount;
    setNumberOfSkeletons(skeletonsCount);
  }, [tabIds, activeTab, deviceType]);

  return (
    <div
      className={cn(styles.container, {
        [styles.left]: deviceType.isLargeDesktop && imagePosition === "left",
        [styles.right]: deviceType.isLargeDesktop && imagePosition === "right"
      })}
    >
      <BestsellersCategoryImage imageUrl={currentImage} />
      <div className={styles.content}>
        {isInitialLoading ? (
          <SkeletonLine
            style={{
              width: "300px",
              height: "50px",
              borderRadius: "20px",
              marginBottom: "30px"
            }}
          />
        ) : (
          <h2 className={styles.title}>{title}</h2>
        )}
        <div className={styles.tabs}>
          {isInitialLoading
            ? Array.from({ length: tabs.length }).map((_, index) => (
              <SkeletonLine
                key={index}
                style={{
                  width: "185px",
                  height: "50px",
                  borderRadius: "100px",
                  marginLeft: "20px"
                }}
              />
            ))
            : tabs.map((tab, index) => (
              <button
                key={index}
                className={cn(styles.tab, {
                  [styles.active]: activeTab === index
                })}
                onClick={() => handleTabClick(index)}
              >
                {tab.title}
              </button>
            ))}
        </div>
        <div className={styles.products}>
          {isLoading || isInitialLoading
            ? Array.from({ length: numberOfSkeletons }).map((_, index) => (
              <SkeletonBestsellerCard key={index} />
            ))
            : displayedProducts.map((product) => (
              <BestsellersProductCard key={product.id} product={product} />
            ))}
        </div>
        <div className={styles.view}>
          <ViewAllLink
            isLoading={isLoading}
            isFirstLoad={isInitialLoading}
            link={linkForButton}
          />
        </div>
      </div>
    </div>
  );
};

export default BestsellersCategoryBlock;

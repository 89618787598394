import { useAppSelector } from "store/reduxHooks";
import BrandsByLetterModal from "../BrandsByLetterModal/BrandsByLetterModal";

interface BrandsByLetterControllerProps {
  modalRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

const BrandsByLetterController: React.FC<BrandsByLetterControllerProps> = (props) => {
  const { deviceType } = useAppSelector((state) => state.user);

  if (deviceType.isMobile) {
    return null;
  }

  return <BrandsByLetterModal {...props} />;
};

export default BrandsByLetterController;
import arrow from "../../assets/icons/png/arrow.png";

import cn from "classnames";
import styles from "./styles.module.scss";

interface CustomArrowButtonProps {
  diraction: string;
  onClick: () => void;
  className?: string;
}

const CustomArrowButton: React.FC<CustomArrowButtonProps> = (props) => {
  return (
    <button className={props.className}>
      <img
        src={arrow}
        alt="arrow-icon"
        className={cn(styles.arrow, styles[`arrow--${props.diraction}`])}
        onClick={props.onClick}
        draggable="false"
      />
    </button>
  );
};

export default CustomArrowButton;

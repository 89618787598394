import { CustomError, getErrorData } from "utils/getErrorData";
import { getUserToken } from "../utils/localStorage";
import { fetchPrivate } from "utils/fetchPrivate";

export const sendPublicCheaperOrder = async (order) => {
  const data = {
    comment: order.comment,
    contact: order.login,
    // eslint-disable-next-line camelcase
    contact_name: order.name,
    offer_id: order.id
  };

  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/cheaper-order`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }
    );

    const responseData = await res.clone().json();

    if (!res.ok) {
      const errorMessage =
        responseData?.meta?.error ||
        responseData?.code ||
        "Необработанная ошибка при отправке письма";
      throw new Error(errorMessage);
    }

    return res.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
    throw error;
  }
};

export const sendPrivateCheaperOrder = async (order) => {
  const token = getUserToken();
  const data = {
    comment: order.comment,
    contact: order.login,
    // eslint-disable-next-line camelcase
    contact_name: order.name,
    offer_id: order.id
  };

  try {
    const res = await fetchPrivate(
      `${process.env.REACT_APP_BACKEND_URL}/api/private/cheaper-order`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "При отправке письма произошла необработанная ошибка",
        500
      );
    }

    const responseData = await res.clone().json();
    if (!res.ok) {
      const errorMessage = getErrorData(responseData);
      throw new CustomError(errorMessage.message, errorMessage?.code);
    }

    return res.json();
  } catch (error) {
    const errorMessage = getErrorData(error);
    // eslint-disable-next-line no-console
    console.log("Произошла ошибка отправки письма: ", errorMessage.message);
    throw errorMessage;
  }
};

export const cheaperApi = {
  sendPublicCheaperOrder,
  sendPrivateCheaperOrder
};

import cn from "classnames";
import styles from "./styles.module.scss";
interface ProductCardLabelProps {
  iconStyle?: string;
  title: string;
  imgSrc?: string;
  imgAlt?: string;
  className?: string;
  shouldShowTitle?: boolean;
}

const ProductCardLabel: React.FC<ProductCardLabelProps> = (props) => {
  return (
    <div className={cn(styles.root, props.className)}>
      {props.imgSrc && (
        <img
          src={props.imgSrc}
          alt={props.imgAlt || ""}
          className={props.iconStyle}
        />
      )}
      {props.shouldShowTitle && (
        <span className={styles.title}>{props.title}</span>
      )}
    </div>
  );
};

export default ProductCardLabel;

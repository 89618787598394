import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

type Params = Record<string, string>;

const useSearchParamsHandler = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onDeleteParams = useCallback((params?: string[]) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (!params) {
      setSearchParams({});
      return;
    }

    params.forEach((item) => {
      newSearchParams.delete(item);
    });

    setSearchParams(newSearchParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onSaveParams = (params: Params) => {
    Object.entries(params).forEach((item) => {
      searchParams.set(item[0], item[1]);
    });
    setSearchParams(searchParams);
  };

  const onChangeSearchParams = (params: Params) => {
    Object.entries(params).forEach((item) => {
      if (!item[1]?.length) {
        searchParams.delete(item[0]);
        return;
      }
      searchParams.set(item[0], item[1]);
    });
    setSearchParams(searchParams);
  };

  const handleChangeSingleSearchParam = ({ key, queryString }: { key: string, queryString: string }) => {
    if (!queryString.length) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, queryString);
    }

    setSearchParams(searchParams);
  };

  return {
    searchParams,
    onDeleteParams,
    onSaveParams,
    onChangeSearchParams,
    handleChangeSingleSearchParam,
  };
};

export default useSearchParamsHandler;

import { IUserTokens } from "types/IUserTokens";

export const getUserCityStorage = localStorage.getItem("user_city");

export const setTokens = (options: IUserTokens) => {
  localStorage.setItem("accessToken", options.token);
  localStorage.setItem("userKey", options.refreshToken.key);
  localStorage.setItem("refreshToken", options.refreshToken.token);
};

export const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userKey");
  localStorage.removeItem("refreshToken");
  sessionStorage.removeItem("accessToken");
};

export const getUserToken = () => {
  return (
    localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken")
  );
};

export const setValueInStorage = ({
  key,
  value
}: {
  key: string;
  value: string;
}) => {
  localStorage.setItem(key, value);
};

export const getValueFromStorage = (key: string): string | null => {
  const data: string | null = localStorage.getItem(key);
  return data;
};

export const clearValueFromStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const setUserCityStorage = (city: string) => {
  localStorage.setItem("user_city", city);
};

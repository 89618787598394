import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IProductWithOffers } from "types/IProduct";
import { IOldPriceData, IPriceData, ISaleData, IVolumeData } from "../../../../../utils/webWorkers/bestsellersProductCardWebWorker";

import styles from "./styles.module.scss";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";

export type WorkerMessageData =
  | { type: "priceData", data: IPriceData }
  | { type: "saleData", data: ISaleData }
  | { type: "volumeData", data: IVolumeData }
  | { type: "oldPriceData", data: IOldPriceData };

interface BestsellersProductCardProps {
  product: IProductWithOffers;
  cardRef?: React.Ref<HTMLAnchorElement>;
}

const cache = new Map<string, WorkerMessageData[]>();

const BestsellersProductCard: React.FC<BestsellersProductCardProps> = ({ product, cardRef }) => {

  const [priceData, setPriceData] = useState<IPriceData>({ minPrice: null, maxPrice: null });
  const [saleData, setSaleData] = useState<ISaleData>({ salePercent: null });
  const [volumeData, setVolumeData] = useState<IVolumeData>({ minVolume: null, maxVolume: null, volumeType: null });
  const [oldPriceData, setOldPriceData] = useState<IOldPriceData>({ oldPrice: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const productId = product.id.toString();

    if (cache.has(productId)) {
      const cachedData = cache.get(productId);
      cachedData?.forEach(({ type, data }) => {
        if (type === "priceData") setPriceData(data);
        if (type === "saleData") setSaleData(data);
        if (type === "volumeData") setVolumeData(data);
        if (type === "oldPriceData") setOldPriceData(data);
      });
      setLoading(false);
    } else {
      const worker = new Worker(new URL("../../../../../utils/webWorkers/bestsellersProductCardWebWorker", import.meta.url));
      worker.postMessage({ product });

      const workerData: WorkerMessageData[] = [];

      worker.onmessage = (e: MessageEvent<WorkerMessageData>) => {
        const { type, data } = e.data;
        workerData.push({ type, data } as WorkerMessageData);

        if (type === "priceData") setPriceData(data);
        if (type === "saleData") setSaleData(data);
        if (type === "volumeData") setVolumeData(data);
        if (type === "oldPriceData") setOldPriceData(data);

        if (workerData.length === 4) {
          cache.set(productId, workerData);
          setLoading(false);
        }
      };

      return () => {
        worker.terminate();
      };
    }
  }, [product]);

  const productUrl = `/product/${product.id}-${product.product_rewrite_name}`;

  const isSinglePrice = priceData.minPrice === priceData.maxPrice;

  return (
    <Link to={productUrl} className={styles.card} ref={cardRef}>
      <div className={styles.image}>
        <img src={product.image.link} alt={product.name} />
      </div>
      <div className={styles.details}>
        <span className={styles.details__price}>
          {loading && (priceData.minPrice === null || priceData.maxPrice === null) ? (
            <SkeletonLine style={{ width: "100px" }}/>
          ) : isSinglePrice ? (
            `${priceData.minPrice} ₽`
          ) : (
            <>
              {priceData.minPrice}&#8202;₽
              <span className={styles.details__separator}>-</span>
              {priceData.maxPrice}&#8202;₽
            </>
          )}
        </span>
        <div className={styles.details__sale}>
          {loading ? (
            <SkeletonLine style={{ width: "85px", height: "25px", borderRadius: "70px" }}/>
          ) : (
            <>
              {saleData.salePercent && <span className={styles.details__sale__percent}>{!isSinglePrice && "до "} -{saleData.salePercent}%</span>}
              {oldPriceData.oldPrice && <span className={styles["details__old-price"]}>{oldPriceData.oldPrice}₽</span>}
            </>
          )}
        </div>
        <p className={styles.details__name}>{product.name}</p>
        <span className={styles.details__volume}>
          {loading && (volumeData.minVolume === null || volumeData.maxVolume === null || volumeData.volumeType === null) ? (
            <SkeletonLine  style={{ width: "25px", height: "10px" }}/>
          ) : volumeData.minVolume === volumeData.maxVolume ? (
            `${volumeData.minVolume} ${volumeData.volumeType}`
          ) : (
            `${volumeData.minVolume} ${volumeData.volumeType} - ${volumeData.maxVolume} ${volumeData.volumeType}`
          )}
        </span>
      </div>
    </Link>
  );
};

export default BestsellersProductCard;
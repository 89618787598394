import { useAppSelector } from "store/reduxHooks";
import StoriesBlock from "./components/StoriesBlock/StoriesBlock";
import DiscountsBlock from "./components/DiscountsBlock/DiscountsBlock";
import PopularBlock from "./components/PopularBlock/PopularBlock";
import TrendsBlock from "./components/TrendsBlock/TrendsBlock";
// Блок ProfitBlock скрыт до его модифицирования и доработки и перевода полностью на ТС
// import ProfitBlock from "./components/ProfitBlock/ProfitBlock";
import HitsBlock from "./components/HitsBlock/HitsBlock";
import ShoppingAdvantageBlock from "./components/ShoppingAdvantageBlock/ShoppingAdvantageBlock";
import Reviews from "./components/Reviews/Reviews";
import AboutUsBlock from "./components/AboutUsBlock/AboutUsBlock";
import OurNews from "./components/OurNews/OurNews";
import SliderMainBanner from "components/SliderMainBanner/SliderMainBanner";

import { HeadHelmet } from "../../utils";

import styles from "./styles.module.scss";

const MainPage: React.FC = () => {
  const { deviceType, banners } = useAppSelector((state) => ({
    deviceType: state.user.deviceType,
    banners: state.banners.banners,
  }));
  const mataTitle = "Оригинальная парфюмерия и косметика Chanel, Dior, Guerlain | Parfumart.ru";
  const isSliderPresent = banners.length > 0;

  return (
    <>
      <HeadHelmet title={mataTitle} />
      <h1 className={styles.seo}>
      Parfumart.ru: оригинальная парфюмерия и косметика с доставкой по России
      </h1>
      <main className={styles.root}>
        <SliderMainBanner />
        {!isSliderPresent && deviceType.isMobile && (
          <div className={styles.placeholder}></div>
        )}
        {process.env.REACT_APP_COMPONENT_ENABLE_STORIES !== "false" && (
          <StoriesBlock />
        )}
        <DiscountsBlock />
        <PopularBlock />
        {/* ДОБАВИТЬ Typescript к ProfitBlock!!! */}
        {/* ТАК ЖЕ ВНЕСТИ НУЖНЫЕ ИЗМЕНЕНИЯ В ЛОГИКУ блока ProfitBlock!! */}
        {/* {!deviceType.isMobile && <ProfitBlock />} */}
        <HitsBlock />
        <ShoppingAdvantageBlock />
        <TrendsBlock />
        {process.env.REACT_APP_COMPONENT_ENABLE_ABOUT_US !== "false" && (
          <AboutUsBlock />
        )}
        {process.env.REACT_APP_COMPONENT_ENABLE_OUR_NEWS !== "false" && (
          <OurNews />
        )}
        {process.env.REACT_APP_COMPONENT_ENABLE_CUSTOMER_FEEDBACK_MAIN_PAGE !==
          "false" && <Reviews />}
      </main>

    </>
  );
};

export default MainPage;

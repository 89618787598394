import { useAppSelector } from "store/reduxHooks";
import { nanoid } from "@reduxjs/toolkit";

import ShoppingAdvantageItem from "./ShoppingAdvantageItem/ShoppingAdvantageItem";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import shoppingAdvantageLogos from "../../../../utils/constants/shoppingAdvantageLogos";
import MainSlider from "../../../../components/MainSlider/MainSlider";
import { IAdvantageItem } from "types/IAdvantageItem";

import styles from "./styles.module.scss";

const ADVANTAGE_ITEMS: IAdvantageItem[] = [
  {
    id: nanoid(),
    title: "Подлинность",
    imgSrc: shoppingAdvantageLogos.authenticityIcon,
    imgAlt: "authenticity-icon",
    description:
      "Мы гарантируем 100% подлинность каждого товара в нашем магазине",
  },
  {
    id: nanoid(),
    title: "Проверка",
    imgSrc: shoppingAdvantageLogos.checkIcon,
    imgAlt: "check-icon",
    description: "Возможность проверить любой заказ при получении",
  },
  {
    id: nanoid(),
    title: "Доставка",
    imgSrc: shoppingAdvantageLogos.deliveryIcon,
    imgAlt: "delivery-icon",
    description: "Доставка по всей территории России, Республики Крым и г. Севастополя",
  },
  {
    id: nanoid(),
    title: "Возврат",
    imgSrc: shoppingAdvantageLogos.returnIcon,
    imgAlt: "return-icon",
    description:
      "Обмен или возврат в течение 30 дней, если упаковка не нарушена",
  },
  {
    id: nanoid(),
    title: "Оплата",
    imgSrc: shoppingAdvantageLogos.payIcon,
    imgAlt: "pay-icon",
    description:
      "Оплата онлайн, наличными или картой при получении, без дополнительных комиссий",
  },
  {
    id: nanoid(),
    title: "Безопасность",
    imgSrc: shoppingAdvantageLogos.secureItem,
    imgAlt: "secure-icon",
    description: "Безопасность онлайн платежей гарантируется SSL протоколом.",
  },
];

const ShoppingAdvantageBlock: React.FC = () => {
  const { deviceType } = useAppSelector((state) => state.user);

  return (
    <section className={styles.root}>
      <SectionTitle
        title="Преимущества покупок на Parfumart.ru"
        containerStyles={styles["main-title"]}
      />
      {deviceType.isMobile ? (
        <MainSlider
          dotsStyles={styles.dots}
          shouldBeLooped
          shouldAutoSwitched
        >
          {ADVANTAGE_ITEMS.map((item) => {
            return (
              <ShoppingAdvantageItem
                key={item.id}
                imgSrc={item.imgSrc}
                imgAlt={item.imgAlt}
                title={item.title}
                description={item.description}
                containerStyles="keen-slider__slide"
              />
            );
          })}
        </MainSlider>
      ) : (
        <ul className={styles.list}>
          {ADVANTAGE_ITEMS.map((item) => {
            return (
              <ShoppingAdvantageItem
                key={item.id}
                imgSrc={item.imgSrc}
                imgAlt={item.imgAlt}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default ShoppingAdvantageBlock;

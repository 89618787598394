import { useMemo } from "react";
import styles from "./styles.module.scss";
import { CustomExpandingText } from "../../../../../../../../../components/CustomExpandingText/CustomExpandingText";
import { IProperty } from "types/IOffer";

interface AboutProductProps {
  description?: string;
  properties?: IProperty[];
}

interface Value {
  name: string;
  rewrite_name: string;
}

interface FormattedDescription {
  name: string;
  rewrite_name: string;
  values: Value[];
}

export const AboutProduct: React.FC<AboutProductProps> = (props) => {

  const updatedDescription = useMemo(() => {
    if (!Array.isArray(props.properties)) {
      return [];
    }
    const formattedDescription = props.properties.reduce((acc: Record<string, FormattedDescription>, cur: IProperty) => {
      const groupName = cur.property_value.property.rewrite_name;

      if (!acc[groupName]) {
        acc[groupName] = {
          name: cur.property_value.property.name,
          "rewrite_name": groupName,
          values: [{
            name: cur.property_value.name,
            "rewrite_name": cur.property_value.rewrite_name,
          }],
        };
        return acc;
      }

      acc[groupName] = {
        ...acc[groupName],
        values: [
          ...acc[groupName].values,
          {
            name: cur.property_value.name,
            "rewrite_name": cur.property_value.rewrite_name,
          },
        ],
      };
      return acc;
    }, {} as Record<string, FormattedDescription>);
    return Object.values(formattedDescription);
  }, [props.properties]);

  return (
    <>
      {props.description ?
        <div className={styles.description} itemProp="description" >
          <CustomExpandingText text={props.description} />
        </div>
        :
        <div className={styles["description-empty"]}>
          <h2>Нет описания</h2>
        </div>
      }

      <ul className={styles["properties-list"]}>
        {updatedDescription.map((property, idx) => {
          return (
            <li
              key={`${property.rewrite_name}-${idx}`}
              className={styles["properties-list__item"]}
            >
              <span>{property.name}</span>
              <span className={styles["properties-list__item-separator"]} />
              <span className={styles["properties-list__item-values"]}>
                {property.values.map((item, index) => {
                  const isLast = index === property.values.length - 1;
                  const formattedName = isLast ? item.name : `${item.name}, `;
                  return <span key={index}>{formattedName}</span>;
                })}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};
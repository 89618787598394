import React from "react";

import BestsellersCategoryItem from "./components/BestsellersCategoryItem";
import styles from "./styles.module.scss";
import { categories } from "utils/constants/bestsellersConstants";


const BestsellersCategoryTabsBlock: React.FC = () => {
  return (
    <div className={styles.container}>
      {categories.map((category, index) => (
        <BestsellersCategoryItem
          key={index}
          title={category.title}
          image={category.image}
          link={category.link}
        />
      ))}
    </div>
  );
};

export default BestsellersCategoryTabsBlock;
import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import SingleImageLoader from "components/UI/SingleImageLoader/SingleImageLoader";

interface BestsellersCategoryItemProps {
  title: string;
  image: string;
  link: string;
}

const BestsellersCategoryItem: React.FC<BestsellersCategoryItemProps> = ({ title, image, link }) => {
  return (
    <Link to={link} className={styles.item}>
      <div className={styles.wrapper}>
        <SingleImageLoader src={image} alt={title} className={styles.image} />
        <div className={styles.title}>{title}</div>
      </div>
    </Link>
  );
};

export default BestsellersCategoryItem;

import { SkeletonImg } from "../SkeletonImg/SkeletonImg";
import { SkeletonLine } from "../SkeletonLine/SkeletonLine";
import styles from "./styles.module.scss";

const SkeletonBestsellerCard: React.FC = () => {
  return (
    <div className={styles.card}>
      <SkeletonImg className={styles["custom-avatar"]}/>
      <div className={styles.card__details}>
        <SkeletonLine style={{ width: "220px", height: "34px", borderRadius: "20px" }} />
        <SkeletonLine style={{ width: "181px", height: "20px", borderRadius: "20px" }} />
        <SkeletonLine style={{ width: "201px", height: "20px", borderRadius: "20px" }} />
        <SkeletonLine style={{ width: "135px", height: "20px", borderRadius: "20px" }} />
      </div>
    </div>
  );
};

export default SkeletonBestsellerCard;
import { useState } from "react";

import telegramIcon from "../../../../../../../assets/icons/svg/iconoir_telegram-circle.svg";
import vkIcon from "../../../../../../../assets/icons/svg/iconoir_whatsapp-circle.svg";
import CustomDropDown from "../../../../../../../components/CustomDropDown/CustomDropDown";

import { ReactComponent as PlusIcon } from "../../../../../../../assets/icons/svg/plus-icon.svg";

import cn from "classnames";
import styles from "./styles.module.scss";

interface HelpDropDownProps {
  isLoading?: boolean;
}

const HelpDropDown: React.FC<HelpDropDownProps> = ({ isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CustomDropDown isOpen={isOpen} containerStyles={cn(styles.dropdown, {
      [styles["disable"]]: isLoading,
    })}>
      {{
        buttonElement: (
          <button className={styles.button} onClick={onClickHandler}>
            <p className={styles.button__info}>Помощь с выбором</p>
            <div className={styles["plus-icon-wrapper"]}>
              <PlusIcon
                className={cn(styles["plus-icon"], {
                  [styles["plus-icon--rotated"]]: isOpen,
                })}
              />
            </div>
          </button>
        ),
        dropdownElement: (
          <div className={styles.help}>
            <div className={styles.help__text}>
              Связаться с консультантом красоты
            </div>
            <div className={styles["social-media"]}>
              <a
                href={`${process.env.REACT_APP_SOCIAL_NET_LINK_TLG_HELP}`}
                rel='noreferrer'
                target='_blank'
                className={styles["social-media__icon"]}
              >
                <img src={telegramIcon} alt='telegram-icon' className={styles["social-media__icon-tg"]} />
              </a>

              <a
                href={`${process.env.REACT_APP_SOCIAL_NET_LINK_WHATSAPP_HELP}`}
                rel='noreferrer'
                target='_blank'
                className={styles["social-media__icon"]}
              >
                <img src={vkIcon} alt='vk-icon' />
              </a>

            </div>
          </div>
        ),
      }}
    </CustomDropDown>
  );
};

export default HelpDropDown;

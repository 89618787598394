import { useEffect, useId, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";

import useUpdateEffect from "../../../utils/hooks/useUpdateEffect";

import useOutsideClick from "../../../utils/hooks/useOutsideClick";
import { ReactComponent as CrossIcon } from "../../../assets/icons/svg/cross-rounded.svg";

import cn from "classnames";
import styles from "./styles.module.scss";

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  headerTitle?: string;
  bottomsheetStyles?: string;
  headerLineStyles?: string;
  shouldBeOnFullScreen?: boolean;
  hideBackground?: boolean;
  disableClickOutside?: boolean;
  onEnter?: () => void;
  onEntered?: () => void;
  onExited?: () => void;
}

const BottomSheet: React.FC<BottomSheetProps> = (props) => {
  const [shouldHideBackground, setShouldHideBackground] = useState(false);
  const [shouldUnmount, setShouldUnmount] = useState(false);
  const bottomsheetRef = useRef<HTMLDivElement | null>(null);
  const id = useId();

  useOutsideClick({
    ref: bottomsheetRef,
    cb: props.onClose,
    disableClickOutside: props.disableClickOutside
  });

  useEffect(() => {
    if (props.isOpen) {
      setShouldUnmount(false);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [props.isOpen]);

  useUpdateEffect(() => {
    if (!bottomsheetRef.current) {
      return;
    }

    const onTransitionHandler = (e: TransitionEvent) => {
      if (e.target !== bottomsheetRef.current) {
        return;
      }
      setShouldUnmount(!props.isOpen);
    };

    bottomsheetRef.current.addEventListener(
      "transitionend",
      onTransitionHandler
    );
    return () => {
      bottomsheetRef.current?.removeEventListener(
        "transitionend",
        onTransitionHandler
      );
    };
  }, []);

  const onEnterHandler = () => {
    setShouldHideBackground(false);
    props.onEnter?.();
  };

  const onExitedHandler = () => {
    setShouldHideBackground(true);
    props.onExited?.();
  };

  return createPortal(
    <CSSTransition
      in={props.isOpen}
      timeout={100}
      nodeRef={bottomsheetRef}
      classNames={{
        enterActive: styles["bottomsheet-enter-active"],
        enterDone: styles["bottomsheet-enter-done"],
        exit: styles["bottomsheet-exit"],
        exitDone: styles["bottomsheet-exit-done"]
      }}
      onEnter={onEnterHandler}
      onEntered={props.onEntered}
      onExited={onExitedHandler}
      mountOnEnter
    >
      <>
        <div
          id={id}
          ref={bottomsheetRef}
          className={cn(styles.bottomsheet, props.bottomsheetStyles)}
        >
          {!shouldUnmount && (
            <div
              className={cn(styles["bottomsheet__content-wrapper"], {
                [styles["bottomsheet__content-wrapper--full"]]:
                  props.shouldBeOnFullScreen
              })}
            >
              <div
                className={cn(
                  styles.bottomsheet__header,
                  props.headerLineStyles
                )}
              >
                <button onClick={props.onClose}>
                  <CrossIcon onClick={props.onClose} />
                </button>
                {!!props.headerTitle && <span>{props.headerTitle}</span>}
              </div>
              {props.children}
            </div>
          )}
        </div>
        {!props.hideBackground && !shouldHideBackground && !shouldUnmount && (
          <div
            className={cn(styles.background, {
              [styles["background--active"]]: props.isOpen
            })}
          />
        )}
      </>
    </CSSTransition>,
    document.body
  );
};

export default BottomSheet;

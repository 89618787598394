import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";

import {
  addUserFavoriteProduct,
  getProductOffersByIds,
  removeUserFavoriteProduct
} from "../../../../../store/user/userThunks/userThunks";
import {
  getMaxProductPrice,
  getMinProductPrice
} from "../../../../../utils/getProductPrices";

import CustomButton from "../../../../../components/CustomButton/CustomButton";
import deleteIcon from "../../../../../assets/icons/png/delete-icon.png";
import heartFoolIcon from "../../../../../assets/icons/png/heart_fool-icon.png";

import cn from "classnames";
import styles from "./styles.module.scss";
import ProductCardOffersModal from "../../../../../components/UI/ProductCardOffersModal/ProductCardOffersModal";
import ModalRedirect from "../../../../../components/ModalRedirect/ModalRedirect";
import { updateFavouriteProducts } from "../../../../../store/user/user";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { IFavouriteProduct } from "types/IFavouriteProduct";
import { IEcommerceYandex } from "types/IEcommerceYandex";
import { getErrorData } from "utils/getErrorData";
import { toast } from "react-toastify";
import LoadingText from "components/UI/LoadingText/LoadingText";

interface ISelectedProductItemProps {
  isFavorite: boolean;
  item: IFavouriteProduct;
}

const SelectedProductItem: React.FC<ISelectedProductItemProps> = ({
  isFavorite,
  item
}) => {
  const deviceType = useAppSelector((state) => state.user.deviceType);
  const [selectedProduct, setSelectedProduct] = useState(item);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showSelectOfferModal, setShowSelectOfferModal] = useState(false);
  const [isOpenRedirectModalCart, setIsOpenRedirectModalCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productUrl, setProductUrl] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const modalRef = useRef<HTMLDivElement | null>(null);
  const modalRedirectCartRef = useRef<HTMLDivElement | null>(null);
  const newParfumartUrlProduct = `${process.env.REACT_APP_REDIRECT_PRODUCT_URL}/${item.id}-${item.product_rewrite_name}`;

  const noImageLink = useMemo(() => {
    return selectedProduct?.category?.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [selectedProduct?.category?.rewrite_name]);

  const productPrice = useMemo(() => {
    if (!selectedProduct.offers?.length) {
      return {
        min: "",
        max: ""
      };
    }

    if (selectedProduct.offers?.length === 1) {
      return {
        min: getMinProductPrice(selectedProduct.offers),
        max: ""
      };
    }

    return {
      min: getMinProductPrice(selectedProduct.offers),
      max: getMaxProductPrice(selectedProduct.offers)
    };
  }, [selectedProduct.offers]);

  const deleteButtonTitle = useMemo(() => {
    if (isDeleted) {
      return "Вернуть в избранное";
    }
    return "Удалить из избранного";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorite, isDeleted]);

  useEffect(() => {
    if (selectedProduct.id && selectedProduct.product_rewrite_name) {
      let url = `/product/${selectedProduct.id}-${selectedProduct.product_rewrite_name}`;
      if (
        Array.isArray(selectedProduct.offers) &&
        selectedProduct.offers.length > 0
      ) {
        url += `?type=${selectedProduct.offers[0].id}`;
      }
      setProductUrl(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProduct.id,
    selectedProduct.product_rewrite_name,
    selectedProduct.offers
  ]);

  useEffect(() => {
    if (!selectedProduct.id) return;
    (async () => {
      try {
        const data = await dispatch(
          getProductOffersByIds(selectedProduct.id)
        ).unwrap();
        setSelectedProduct((prev) => {
          return {
            ...prev,
            offers: data.offers
          };
        });
      } catch (err) {
        const errorData = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error(
          `При получении предложений по продукту с ID: ${selectedProduct.id} произошла ошибка:`,
          errorData.message
        );
      }
    })();
  }, [dispatch, selectedProduct.id]);

  const onDeleteProduct = async () => {
    try {
      setIsLoading(true);
      const data = await dispatch(
        removeUserFavoriteProduct({ product: selectedProduct.id })
      ).unwrap();
      if (data?.length) {
        setIsDeleted(true);
      }
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(
        `При удалении из избранного товара с ID: ${selectedProduct.id} произошла ошибка: `,
        errorData.message
      );
      setIsDeleted(false);
      toast.error(errorData.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onRestoreProduct = async () => {
    try {
      setIsLoading(true);
      const updatedFavouriteProducts = await dispatch(
        addUserFavoriteProduct(selectedProduct.id)
      ).unwrap();

      setSelectedProduct((prev) => ({
        ...prev,
        isFavorite: true
      }));

      dispatch(updateFavouriteProducts(updatedFavouriteProducts));
      setIsDeleted(false);
    } catch (err) {
      const errorData = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(
        `При восстановлении избранного товара с ID: ${selectedProduct.id} произошла ошибка: `,
        errorData.message
      );
      setIsDeleted(true);
      toast.error(errorData.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenSelectOfferModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedProduct(selectedProduct);
    setShowSelectOfferModal(true);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (deviceType.isMobile || deviceType.isTablet) {
      navigate(productUrl);
    } else {
      onOpenSelectOfferModal(e);
    }
  };

  const onCloseSelectOfferModal = () => {
    setShowSelectOfferModal(false);
  };

  const onCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e) e.stopPropagation();
    setIsOpenRedirectModalCart(false);
  };

  const productMinDescription = useMemo(() => {
    if (!item || !item.offers || item.offers.length === 0) {
      return "";
    }

    const productItemWithVolume = item.offers.find((offer) => {
      if (!offer.offerVolumes) return false;
      return offer.offerVolumes.some((elem) => elem.value_type);
    });

    if (!productItemWithVolume) {
      return "";
    }

    const offerVolumesCopy = [...productItemWithVolume.offerVolumes];
    const productVolume = offerVolumesCopy[0]?.value_type;

    if (!productVolume) {
      return "";
    }

    return `Объем продукта: ${productVolume}`;
  }, [item]);

  const handleEcommerceAction = async () => {
    // yandex ecommerce 'click'
    const ecommerceClickData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.click]: {
          products: [
            {
              id: item.id?.toString(),
              name: item.name,
              category: item.category.name,
              brand:
                item.brands && !!item.brands.length
                  ? item.brands[0].brand.name
                  : ""
            }
          ]
        }
      }
    };
    await handleYandexEcommerce(ecommerceClickData);
  };

  return (
    <div className={cn(styles.root, { [styles["root--deleted"]]: isDeleted })}>
      <img
        src={selectedProduct.image?.link || noImageLink}
        alt={`${selectedProduct.name}`}
        className={cn(styles.logo, { [styles["logo--deleted"]]: isDeleted })}
      />
      <div className={styles.product}>
        <h4 className={styles.product__title}>{selectedProduct.name}</h4>
        <div className={styles.product__info}>
          <span className={styles["product__vendor-code"]}>
            {`Артикул: ${selectedProduct.vendorCode}`}
          </span>
          {deviceType.isMobile && (
            <button
              className={cn(
                styles["product__action-button"],
                styles["product__action-button--mobile"]
              )}
              onClick={isDeleted ? onRestoreProduct : onDeleteProduct}
            >
              <div className={styles["product__action-button-img"]}>
                <img
                  src={!isDeleted ? deleteIcon : heartFoolIcon}
                  alt="action-icon"
                />
              </div>
              {isLoading ? (
                <LoadingText text={deleteButtonTitle} />
              ) : (
                <span>{deleteButtonTitle}</span>
              )}
            </button>
          )}
          <div className={styles["product__actions-wrapper"]}>
            <Link
              to={isDeleted ? "#" : productUrl}
              className={cn(styles.product__link, {
                [styles["product__link--deleted"]]: isDeleted
              })}
              onClick={handleEcommerceAction}
            >
              Перейти к товару
            </Link>
            {!!productPrice.min && !!productPrice.max && (
              <div className={styles.product__price}>
                <span>{`От ${productPrice.min} ₽`}</span>
                <span className={styles["product__price--second"]}>
                  {`До ${productPrice.max} ₽`}
                </span>
              </div>
            )}
            {!!productPrice.min && !productPrice.max && (
              <div className={styles.product__price}>
                <span>{`${productPrice.min} ₽`}</span>
              </div>
            )}
            <CustomButton
              title="В корзину"
              onClick={handleButtonClick}
              className={cn(styles["product__cart-button"], {
                [styles["product__cart-button--deleted"]]: isDeleted
              })}
            />
          </div>
        </div>
        {!deviceType.isMobile && (
          <button
            className={cn(
              styles["product__action-button"],
              styles["product__action-button--desktop"]
            )}
            onClick={isDeleted ? onRestoreProduct : onDeleteProduct}
          >
            <div className={styles["product__action-button-img"]}>
              <img
                src={!isDeleted ? deleteIcon : heartFoolIcon}
                alt="action-icon"
              />
            </div>
            {isLoading ? (
              <LoadingText text={deleteButtonTitle} />
            ) : (
              <span>{deleteButtonTitle}</span>
            )}
          </button>
        )}
      </div>
      {showSelectOfferModal && (
        <ProductCardOffersModal
          logoSrc={item.reducedImage?.link ?? noImageLink}
          title={item.name}
          productDescription={productMinDescription}
          offers={selectedProduct.offers}
          productUrl={productUrl}
          modalRef={modalRef}
          onClose={onCloseSelectOfferModal}
          productItem={item}
        />
      )}
      <ModalRedirect
        onClose={onCloseModal}
        url={newParfumartUrlProduct}
        isOpen={isOpenRedirectModalCart}
        modalRef={modalRedirectCartRef}
        textForRedirect={"оформления заказа"}
      />
    </div>
  );
};

export default SelectedProductItem;

import { useEffect, useState } from "react";

import BestsellersHeaderBanner from "./components/BestsellersHeaderBanner/BestsellersHeaderBanner";
import BestsellersCategoryTabsBlock from "./components/BestsellersCategoryTabsBlock/BestsellersCategoryTabsBlock";
import BestsellersProfitBuyBlock from "./components/BestsellersProfitBuyBlock/BestsellersProfitBuyBlock";
import { IProductWithOffers } from "types/IProduct";
import BestsellersCategoryBlock, { ITab } from "./components/BestsellersCategoryBlock/BestsellersCategoryBlock";
import { bodyCareTabIds, bodyCareTabs, hairCareTabIds, hairCareTabs, linksForTabsButton, linksProfitButton, productProfitIds } from "../../../utils/constants/bestsellersConstants";
import cosmeticImage from "../../../assets/bestseller/cosmetic-image.png";
import cosmeticImageLarge from "../../../assets/bestseller/close-up.png";
import bodyCareImageSmall from "../../../assets/bestseller/still-life-banner-s.png";
import bodyCareImageLarge from "../../../assets/bestseller/still-life-banner-l.png";
import BestsellersBodyBanner from "./components/BestsellersBodyBanner/BestsellersBodyBanner";
import useProductsByIds from "utils/hooks/useProductsByIds";
import getProductsByArrayIds from "utils/helpers/getProductsByArrayIds";
import BestsellersLikeItBlock from "./components/BestsellersLikeItBlock/BestsellersLikeItBlock";
import getBestsellerProducts from "utils/helpers/getBestsellerProducts";
import { ICategoryTab } from "types/ICategoryTab";
import { CustomError, getErrorData } from "utils/getErrorData";

import styles from "./styles.module.scss";

const Bestsellers: React.FC = () => {
  const { products, isLoading } = useProductsByIds(productProfitIds);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [ profitProducts, setProfitProducts ] = useState<IProductWithOffers[]>([]);
  const [hairCareTab, setHairCareTab] = useState<ITab[]>([
    { title: "Краска для волос", products: [] },
    { title: "Окислители", products: [] },
    { title: "Маска для волос", products: [] },
  ]);

  const [ bodyCareTab, setBodyCareTab ] = useState<ITab[]>([
    { title: "Для душа", products: [] },
    { title: "Дезодоранты", products: [] },]);

  const fetchProductsForTab = async (tab: ICategoryTab, tabIds: number[]) => {
    let products: IProductWithOffers[] = [];

    try {
      if (tabIds.length < 4) {
        const { products: bestsellerProducts } = await getBestsellerProducts(tab);
        products = bestsellerProducts;
      } else {
        products = await getProductsByArrayIds(tabIds);
      }
    } catch (err) {
      const errorData = getErrorData(err);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return { title: tab.productType, products };
  };

  const fetchInitialTabsProducts = async () => {
    try {
      const updatedHairCareTab = await fetchProductsForTab(hairCareTabs[0], hairCareTabIds[0]);
      const updatedBodyCareTab = await fetchProductsForTab(bodyCareTabs[0], bodyCareTabIds[0]);

      setHairCareTab(prevTabs => [
        { ...prevTabs[0], products: updatedHairCareTab.products },
        ...prevTabs.slice(1)
      ]);

      setBodyCareTab(prevTabs => [
        { ...prevTabs[0], products: updatedBodyCareTab.products },
        ...prevTabs.slice(1)
      ]);
    } catch (err) {
      const errorMessage = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error(errorMessage.message);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialTabsProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && products.length > 0) {
      const transformedProducts = products
        .map(productResponse => ({
          ...productResponse.product,
          offers: productResponse.offers.filter(offer => offer.available)
        }))
        .filter(product => product.offers.length > 0);
      setProfitProducts(transformedProducts);
    }
  }, [products, isLoading]);

  const firstSixProducts = profitProducts.slice(0, 6);
  const secondSixProducts = profitProducts.slice(6, 12);
  const thirdSixProducts = profitProducts.slice(12, 18);

  return (
    <div className={styles.root}>
      <BestsellersHeaderBanner />
      <BestsellersCategoryTabsBlock />
      <BestsellersProfitBuyBlock isLoading={isLoading} products={firstSixProducts} link={linksProfitButton.firstProfitBlock} />
      <BestsellersBodyBanner />
      <BestsellersCategoryBlock
        title="Уход за волосами"
        imageUrl={bodyCareImageSmall}
        imageUrlLargeDesktop={bodyCareImageLarge}
        imagePosition="left"
        tabs={hairCareTab}
        tabIds={hairCareTabIds}
        linkForButton={linksForTabsButton.hairСareTab}
        categoryTabs={hairCareTabs}
        isInitialLoading={isInitialLoading}
      />
      <hr className={styles.divider} />
      <BestsellersProfitBuyBlock isLoading={isLoading} products={secondSixProducts} link={linksProfitButton.secondProfitBlock} />
      <hr className={styles.divider} />
      <BestsellersCategoryBlock
        title="Уход за телом"
        imageUrl={cosmeticImage}
        imageUrlLargeDesktop={cosmeticImageLarge}
        imagePosition="left"
        tabs={bodyCareTab}
        tabIds={bodyCareTabIds}
        linkForButton={linksForTabsButton.bodyCareTab}
        categoryTabs={bodyCareTabs}
        isInitialLoading={isInitialLoading}
      />
      <hr className={styles.divider} />
      <BestsellersProfitBuyBlock isLoading={isLoading} products={thirdSixProducts}link={linksProfitButton.thirdProfitBlock} />
      <hr className={styles.divider} />
      <BestsellersLikeItBlock />
    </div>
  );
};

export default Bestsellers;
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { removeUser } from "../../../store/user/user";
import { clearValueFromStorage, removeTokens, setValueInStorage } from "../../../utils/localStorage";

import Portal from "../../../HOC/Portal";
import PersonalAreaNavigationContent from "../../../components/PersonalAreaNavigationContent/PersonalAreaNavigationContent";
import ExitModal from "../ExitModal/ExitModal";

import styles from "./styles.module.scss";
import { getUserCart } from "../../../store/user/cartThunks/cartThunks";
import { useAppDispatch } from "store/reduxHooks";

interface PersonalAreaModalProps {
  modalRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

const PersonalAreaModal: React.FC<PersonalAreaModalProps> = (props) => {
  const [isShowExitModal, setIsShowExitModal] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onCloseExitModal = ({ isExit }: { isExit: boolean }) => {
    if (isExit) {
      removeTokens();
      dispatch(removeUser());
      clearValueFromStorage("user_city");
      setValueInStorage({ key: "isLoggedOut", value: "true" });
      navigate("/", { replace: true });
      props.onClose();
      setIsShowExitModal(false);
      dispatch(getUserCart({}));
      return;
    }
    setIsShowExitModal(false);
  };


  return (
    <Portal modalRef={props.modalRef} onClose={props.onClose}>
      <div className={styles.root}>
        <div ref={props.modalRef}>
          <PersonalAreaNavigationContent
            containerStyles={styles["personal-area__navigation"]}
            onClose={props.onClose}
            onContinueExit={() => setIsShowExitModal(true)}
          />
          {
            isShowExitModal &&
          <ExitModal
            onClose={onCloseExitModal}
          />
          }
        </div>
        <div className={styles.background} />
      </div>
    </Portal>
  );
};

export default PersonalAreaModal;
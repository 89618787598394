import { productsApi } from "api/productsApi";
import { isError } from "store/user/userThunks/userThunks";
import { IProductResponse } from "types/IGetProductOffersThunk";
import { IProductWithOffers } from "types/IProduct";
import { CustomError, getErrorData, IGenericResponseRoot } from "utils/getErrorData";

export const getProductsByArrayIds = async (ids: number[]): Promise<IProductWithOffers[]> => {
  try {
    const productPromises = ids.map(id => productsApi.getProductById(id));
    const results = await Promise.allSettled(productPromises);

    const successfulResults = results
      .filter(result => result.status === "fulfilled")
      .map(result => (result as PromiseFulfilledResult<IGenericResponseRoot<IProductResponse>>).value);

    if (isError(successfulResults)) {
      const errorData = getErrorData(successfulResults);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return successfulResults.map(result => ({
      ...result.response.product,
      offers: result.response.offers
    }));
  } catch (error) {
    const errorData = getErrorData(error);
    throw new CustomError(errorData.message, errorData?.code);
  }
};

export default getProductsByArrayIds;
import cn from "classnames";
import styles from "./styles.module.scss";
import { IBrandsState, FilterType } from "../../BrandsByLetterContent";

interface CurrentBrandLetterBlockProps {
  selectedLetter: string;
  activeFilter: FilterType;
  onSelectFilter: (filter: FilterType) => void;
  brands: IBrandsState;
}

const CurrentBrandLetterBlock: React.FC<CurrentBrandLetterBlockProps> = (props) => {
  const changeFilterTab = (filter: FilterType) => {
    if (!props.brands[filter]?.length) {
      return;
    }
    props.onSelectFilter(filter);
  };

  return (
    <div className={styles.root}>
      <div className={cn(styles["content-wrapper"], styles["content-wrapper__display-none"])}>
        <p className={styles.text}>Выберите бренд</p>
        <p className={styles.letter}>{props.selectedLetter}</p>
      </div>
      <div className={styles["content-wrapper"]}>
        <button
          className={cn(styles.text, {
            [styles["text--selected"]]: props.activeFilter === "all",
          })}
          onClick={() => changeFilterTab("all")}
        >
          Все бренды
        </button>
        <button
          className={cn(styles.text, {
            [styles["text--selected"]]: props.activeFilter === "parfumeria",
            [styles["text--disabled"]]: !props.brands["parfumeria"]?.length,
          })}
          onClick={() => changeFilterTab("parfumeria")}
        >
          Бренды парфюмерии
        </button>
        <button
          className={cn(styles.text, {
            [styles["text--selected"]]: props.activeFilter === "kosmetika",
            [styles["text--disabled"]]: !props.brands["kosmetika"]?.length,
          })}
          onClick={() => changeFilterTab("kosmetika")}
        >
          Бренды косметики
        </button>
      </div>
    </div>
  );
};

export default CurrentBrandLetterBlock;

import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";

import { setUserCurrentCity } from "../../../../../../store/user/user";
import useCitiesSearch from "../../../../../../utils/hooks/useCitiesSearch";

import SelectCityInnerContent from "../../../../../../components/SelectCityInnerContent/SelectCityInnerContent";
import CitiesSuggestionContent from "../../../../../../components/CitiesSuggestionContent/CitiesSuggestionContent";
import BottomSheet from "../../../../../../components/UI/Bottomsheet/Bottomsheet";
import geoIcon from "../../../../../../assets/header_icons/icon_geo.svg";

import cn from "classnames";
import styles from "./styles.module.scss";

interface SelectCityTabProps {
  buttonStyles?: string;
  iconStyles?: string;
  cb?: () => void;
  children?: React.ReactNode;
}

const SelectCityTab: React.FC<SelectCityTabProps> = (props) => {
  const { currentCity } = useAppSelector((state) => state.user);

  const [isOpenMainBS, setIsOpenMainBS] = useState(false);
  const [isOpenSelectCityBS, setIsOpenSelectCityBS] = useState(false);

  const {
    searchValue,
    searchedCities,
    isLoading,
    isNoResultSearch,
    onChangeSearchValue,
    onResetCitiesSearch,
  } = useCitiesSearch({ limit: 15 });

  const dispatch = useAppDispatch();

  const onToggleIsOpenMainBS = () => {
    setIsOpenMainBS(!isOpenMainBS);
    props.cb?.();
  };

  const onCloseMainBSHandler = () => {
    setIsOpenMainBS(false);
  };

  const onCloseSelectCityBSHandler = () => {
    setIsOpenSelectCityBS(false);
    onResetCitiesSearch();
  };

  const setCity = (city: string) => {
    dispatch(setUserCurrentCity(city));
    onResetCitiesSearch();
    onCloseMainBSHandler();
    setIsOpenSelectCityBS(false);
  };

  return (
    <li className={styles.root}>
      <button
        onClick={onToggleIsOpenMainBS}
        className={cn(styles.tab__button, props.buttonStyles)}
      >
        <img
          src={geoIcon}
          alt="geo_icon"
          className={cn(styles["tab__geo-icon"], props.iconStyles)}
        />
        {props.children}
      </button>
      <BottomSheet
        isOpen={isOpenMainBS}
        onClose={onCloseMainBSHandler}
        disableClickOutside={isOpenSelectCityBS}
      >
        <CitiesSuggestionContent
          cityTitle={currentCity}
          onClose={onCloseMainBSHandler}
          onShowSelectCities={() => setIsOpenSelectCityBS(true)}
        />
      </BottomSheet>
      <BottomSheet
        isOpen={isOpenSelectCityBS}
        onClose={onCloseSelectCityBSHandler}
        shouldBeOnFullScreen
        hideBackground
      >
        <SelectCityInnerContent
          searchValue={searchValue}
          searchedCities={searchedCities}
          isLoading={isLoading}
          isNoResultSearch={isNoResultSearch}
          onChange={onChangeSearchValue}
          onClose={() => setIsOpenSelectCityBS(false)}
          selectCity={setCity}
        />
      </BottomSheet>
    </li>
  );
};

export default SelectCityTab;

import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import {
  addUserFavoriteProduct,
  removeUserFavoriteProduct
} from "../../store/user/userThunks/userThunks";
// import { getProductPrices } from "../../utils/getProductPrices";

import CustomButton from "../CustomButton";
import CustomLink from "../CustomLink";
import ProductCardLabel from "../ProductCardLabel";
import ProductCardOffersModal from "../UI/ProductCardOffersModal";

import Cosmetics from "../../assets/icons/svg/cosmetics.svg";
import heartEmptyIcon from "../../assets/icons/png/heart_empty-icon.png";
import heartFoolIcon from "../../assets/icons/png/heart_fool-icon.png";
import newIcon from "../../assets/icons/sale-icons/new.svg";
import saleIcon from "../../assets/icons/sale-icons/saleIcon.svg";
import fireIcon from "../../assets/icons/sale-icons/trace.svg";

import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { IProductWithOffers } from "types/IProduct";
import { IEcommerceYandex } from "types/IEcommerceYandex";
import { IProductVolume } from "types/IProductVolume";
import { SkeletonLine } from "components/UI/Skeleton/SkeletonLine/SkeletonLine";
import { IProductCardWorkerResults } from "utils/webWorkers/productCardWebWorker";

import styles from "./styles.module.scss";
interface IProductCardProps {
  product: IProductWithOffers;
  onToggleIsOffersModalOpenedStatus?: React.Dispatch<SetStateAction<boolean>>;
}

const ProductCard: React.FC<IProductCardProps> = ({
  product,
  onToggleIsOffersModalOpenedStatus
}) => {
  const { user, deviceType } = useAppSelector((state) => state.user);
  const [showSelectOfferModal, setShowSelectOfferModal] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const [isNewProduct, setIsNewProduct] = useState<boolean | null>(null);
  const [isBestsellerProduct, setIsBestsellerProduct] = useState<
    boolean | null
  >(null);
  const [isSaleProduct, setIsSaleProduct] = useState<boolean | null>(null);
  const [productMinDescription, setProductMinDescription] = useState<
    string | null
  >(null);
  const [productPrice, setProductPrice] = useState<{
    min: string;
    max: string;
  } | null>(null);
  const [productAmount, setProductAmount] = useState<IProductVolume | null>(
    null
  );
  const [salePercent, setSalePercent] = useState<number | null>(null);

  const modalRef = useRef<HTMLDivElement | null>(null);

  const isProductInFavourite = useMemo(() => {
    if (!Array.isArray(user.favouriteProducts)) {
      return false;
    }
    const idx = user.favouriteProducts.findIndex(
      (item) => item.product.id === product.id
    );
    return idx !== -1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.favouriteProducts]);

  const [isFavorite, setIsFavorite] = useState(isProductInFavourite);

  const dispatch = useAppDispatch();
  const heartIconRef = useRef<HTMLDivElement | null>(null);

  // Производим вычесления параметров карточки товара

  // ПОДСЧЕТ ЧЕРЕЗ web worker

  useEffect(() => {
    const shouldCalculate = deviceType.isLargeDesktop || deviceType.isDesktop;
    const worker = new Worker(
      new URL("../../utils/webWorkers/productCardWebWorker.ts", import.meta.url)
    );

    worker.postMessage({ product, shouldCalculate });

    worker.onmessage = (e: MessageEvent<IProductCardWorkerResults>) => {
      const results = e.data;
      setIsNewProduct(results.isNewProduct);
      setIsBestsellerProduct(results.isBestsellerProduct);
      setIsSaleProduct(results.isSaleProduct);
      setProductMinDescription(results.productMinDescription);
      setProductPrice(results.productPrice);
      setProductAmount(results.productAmount);
      setSalePercent(results.salePercent);
    };

    return () => {
      worker.terminate();
    };
  }, [deviceType.isDesktop, deviceType.isLargeDesktop, product]);

  // АСИНХРОННЫЙ ПОДСЧЕТ СВОЙСТВ
  // useEffect(() => {
  //   const calculateValues = async () => {
  //     try {
  //       // Считаем "isNewProduct"
  //       const isNew = await new Promise<boolean>((resolve) => {
  //         resolve(product.offers.some((offer) => !!offer.new_label));
  //       });
  //       setIsNewProduct(isNew);

  //       // Считаем "isBestsellerProduct"
  //       const isBestseller = await new Promise<boolean>((resolve) => {
  //         resolve(product.offers.some((offer) => !!offer.bestseller));
  //       });
  //       setIsBestsellerProduct(isBestseller);

  //       // Считаем "isSaleProduct"
  //       const isSale = await new Promise<boolean>((resolve) => {
  //         resolve(product.offers.some((offer) => !!offer.price_sale));
  //       });
  //       setIsSaleProduct(isSale);

  //       // Считаем "productMinDescription"
  //       const productMinDesc = await new Promise<string | null>((resolve) => {
  //         const productItemWithVolume = product.offers.find((item) => {
  //           if (!item.offerVolumes) return "";
  //           return item.offerVolumes.find((elem) => elem.value_type);
  //         });

  //         if (!productItemWithVolume) return resolve("");

  //         const productVolume =
  //           productItemWithVolume.offerVolumes[0].value_type;

  //         const description = product.offers
  //           .sort((a, b) => a.name.length - b.name.length)[0]
  //           .name.split(`${productVolume} `)[1];

  //         if (!description) return resolve("");

  //         resolve(description[0].toUpperCase() + description.slice(1));
  //       });
  //       setProductMinDescription(productMinDesc);

  //       // Считаем "productPrice"
  //       const prices = await new Promise<{ min: string; max: string } | null>(
  //         (resolve) => {
  //           resolve(getProductPrices(product.offers));
  //         }
  //       );
  //       setProductPrice(prices);

  //       // Вычисляем объем только на десктопах и больших компьютерах - так как в дизайне не предусмотрен рендер объема для мобильных
  //       // Считаем "productAmount"
  //       const amounts = await new Promise<IProductVolume | null>((resolve) => {
  //         if (deviceType.isLargeDesktop || deviceType.isDesktop) {
  //           const productItemWithVolume = product.offers.find((item) => {
  //             if (!item.offerVolumes) return;
  //             return item.offerVolumes.find((elem) => elem.value_type);
  //           });

  //           if (!productItemWithVolume) {
  //             return resolve(null);
  //           }

  //           const productVolume =
  //             productItemWithVolume.offerVolumes[0].value_type ?? "";

  //           if (product.offers.length === 1) {
  //             if (product.offers[0].offerVolumes[0].value && productVolume) {
  //               const productVolumeObj: IProductVolume = {
  //                 min: `${product.offers[0].offerVolumes[0].value}`,
  //                 max: "",
  //                 productVolume
  //               };
  //               return resolve(productVolumeObj);
  //             }
  //             return resolve(null);
  //           }

  //           const offersCopy = [...product.offers];

  //           const productMinValueAmount = offersCopy.sort((a, b) => {
  //             return a.offerVolumes[0]?.value - b.offerVolumes[0]?.value;
  //           })?.[0].offerVolumes?.[0]?.value;

  //           const productMaxValueAmount = offersCopy.sort((a, b) => {
  //             return b.offerVolumes[0]?.value - a.offerVolumes[0]?.value;
  //           })?.[0]?.offerVolumes?.[0]?.value;

  //           if (productMinValueAmount === productMaxValueAmount) {
  //             return resolve({
  //               min: productMinValueAmount?.toString() ?? "",
  //               max: "",
  //               productVolume
  //             });
  //           }

  //           return resolve({
  //             min: productMinValueAmount?.toString() ?? "",
  //             max: productMaxValueAmount?.toString() ?? "",
  //             productVolume
  //           });
  //         }
  //         resolve(null);
  //       });
  //       setProductAmount(amounts);

  //       // Считаем "salePercent"
  //       const salePercentage = await new Promise<number | null>((resolve) => {
  //         if (!product || !Array.isArray(product.offers)) {
  //           return resolve(null);
  //         }

  //         const availableOffers = product.offers.filter(
  //           (offer) => offer.available
  //         );
  //         if (availableOffers.length === 0) {
  //           return resolve(null);
  //         }
  //         const allSame = availableOffers.every((offer) => {
  //           return offer?.percent === availableOffers[0]?.percent;
  //         });

  //         if (allSame) {
  //           return resolve(availableOffers[0].percent || null);
  //         } else {
  //           const maxSalePercent = availableOffers.reduce((max, item) => {
  //             return item.percent && item.percent > max ? item.percent : max;
  //           }, 0);
  //           return resolve(maxSalePercent || null);
  //         }
  //       });
  //       setSalePercent(salePercentage);

  //     } catch (error) {
  //       // eslint-disable-next-line no-console
  //       console.error(
  //         "При вычислении параметров товара произошла необработанная ошибка --->",
  //         error
  //       );
  //     }
  //   };

  //   calculateValues();
  // }, [deviceType.isDesktop, deviceType.isLargeDesktop, product]);

  const brandTitle = useMemo(() => {
    const productWithBrand = product.brands.find((item) => item.brand.name);

    if (!productWithBrand?.brand?.name) return "";

    return productWithBrand.brand.name;
  }, [product.brands]);

  const productUrl = `/product/${product.id}-${product.product_rewrite_name}`;

  const onToggleFavoriteStatus = useCallback(
    async (e: React.MouseEvent<HTMLDivElement> | TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        setIsFavorite(!isFavorite);
        if (!isFavorite) {
          dispatch(addUserFavoriteProduct(product.id));
        } else {
          dispatch(removeUserFavoriteProduct({ product: product.id }));
        }
      } catch (err) {
        // TODO
        // eslint-disable-next-line no-console
        console.error(
          "Произошла ошибка добавить/убрать из избранного товар",
          err
        );
      }
    },
    [dispatch, isFavorite, product.id, setIsFavorite]
  );

  const onOpenSelectOfferModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setShowSelectOfferModal(true);
    onToggleIsOffersModalOpenedStatus?.(true);
  };

  const onCloseSelectOfferModal = () => {
    setShowSelectOfferModal(false);
    onToggleIsOffersModalOpenedStatus?.(false);
  };

  const handleSelectCard = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    // yandex ecommerce 'click'
    const ecommerceClickData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.click]: {
          products: [
            {
              id: product.id?.toString(),
              name: product.name,
              category: product.category.name,
              brand:
                product.brands && !!product.brands.length
                  ? product.brands[0].brand.name
                  : ""
            }
          ]
        }
      }
    };
    await handleYandexEcommerce(ecommerceClickData);
  };

  const LinkToButton = useCallback(() => {
    const customClassName = styles.linkInfo;
    return (
      <CustomLink
        className={customClassName}
        title="Подробнее о товаре"
        path={productUrl}
        isPrimary
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noImageLink = useMemo(() => {
    return product?.category.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [product?.category]);

  const mainSrc = product?.image?.link ? product?.image?.link : noImageLink;

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent): void => {
      if (e.cancelable) {
        e.preventDefault();
        e.stopPropagation();
      }
      onToggleFavoriteStatus(e);
    };

    const heartIconElement = heartIconRef.current;

    if (heartIconElement) {
      heartIconElement.addEventListener("touchstart", handleTouchStart, {
        passive: false
      });
    }

    return () => {
      if (heartIconElement) {
        heartIconElement.removeEventListener("touchstart", handleTouchStart);
      }
    };
  }, [onToggleFavoriteStatus]);

  const isMinEqualMax =
    productPrice?.min && productPrice?.min === productPrice?.max;
  return (
    <>
      <div className={styles.card__wrapper}>
        <Link
          to={productUrl}
          className={styles.card}
          onClick={handleSelectCard}
          itemType="https://schema.org/Product"
          itemScope
        >
          <div className={styles.card__labels}>
            {/* {isStockProduct && (
              <ProductCardLabel title="Акция" imgSrc={mouthpieceIcon} imgAlt="promotion-icon" />
            )} */}
            {isNewProduct ? (
              <ProductCardLabel
                iconStyle={styles.newIcon}
                title="Новинка"
                imgSrc={newIcon}
                imgAlt="new-icon"
                shouldShowTitle
              />
            ) : isBestsellerProduct ? (
              <ProductCardLabel
                title="Бестселлер"
                iconStyle={styles.fireIcon}
                imgSrc={fireIcon}
                imgAlt="best_seller-icon"
                shouldShowTitle
              />
            ) : (
              <div className={styles["card__empty-label"]} />
            )}
          </div>

          {/* Image */}
          <span className={styles["card_img-wrapper"]}>
            {!loadImg && (
              <img src={Cosmetics} className={styles["card_img-svg"]} />
            )}
            <img
              itemProp="image"
              src={mainSrc}
              alt={product.product_rewrite_name}
              className={cn(styles.card_img, {
                [styles["card_img-show"]]: loadImg
              })}
              onLoad={() => setLoadImg(true)}
            />
            {!!user?.phone && (
              <div
                ref={heartIconRef}
                onClick={onToggleFavoriteStatus}
                className={styles["card__heart_icon-wrapper"]}
              >
                <img
                  src={!isFavorite ? heartEmptyIcon : heartFoolIcon}
                  alt="heart-icon"
                  className={styles.header__additional__item_icon}
                />
              </div>
            )}
          </span>
          <div className={styles.card__labels}>
            {isSaleProduct ? (
              <ProductCardLabel
                title="Распродажа"
                iconStyle={styles.saleIcon}
                imgSrc={saleIcon}
                imgAlt="sale-icon"
                className={styles["sale-label"]}
                shouldShowTitle
              />
            ) : (
              <div className={styles["card__empty-label-row"]} />
            )}
          </div>

          {/* Brand */}
          <span className={styles["card_brand-title"]} itemProp="brand">
            {brandTitle}
          </span>

          {/* Title */}
          <span className={styles.card_title} itemProp="name">
            {product.name}
          </span>

          {/* Description */}
          <div className={styles.card_description} itemProp="description">
            {productMinDescription ? (
              productMinDescription
            ) : (
              <span>&nbsp;</span>
            )}
          </div>

          <div
            className={cn(styles.card__price, {
              [styles["card__price-min-content"]]:
                !productPrice?.max || isMinEqualMax
            })}
            itemProp="offers"
            itemType="https://schema.org/Offers"
            itemScope
          >
            <meta itemProp="priceCurrency" content="RUB" />

            {/* Price */}
            {productPrice ? (
              !productPrice.max || isMinEqualMax ? (
                <>
                  <span>
                    <span itemProp="price" content={productPrice.min}>
                      {productPrice.min}
                    </span>
                    <span> ₽ </span>
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <span> От </span>
                    <span itemProp="lowPrice" content={productPrice.min}>
                      {productPrice.min}
                    </span>
                    <span> ₽ </span>
                  </span>

                  <span>
                    <span> До </span>
                    <span itemProp="highPrice" content={productPrice.max}>
                      {productPrice.max}
                    </span>
                    <span> ₽ </span>
                  </span>
                </>
              )
            ) : (
              <SkeletonLine />
            )}
          </div>
          <div>
            {!!salePercent && (
              <ProductCardLabel
                title={`${salePercent}%`}
                imgAlt="sale-icon"
                className={styles["sale-percent"]}
                shouldShowTitle
              />
            )}
          </div>

          {/* Volume */}
          {productAmount && !!productAmount.min && (
            <span
              className={cn(styles.card_amount, {
                [styles["card_amount-min-content"]]: !productAmount.max
              })}
            >
              {!productAmount.max ? (
                <span>{`${productAmount.min} ${productAmount.productVolume}.`}</span>
              ) : (
                <>
                  <span>{`От ${productAmount.min} ${productAmount.productVolume}`}</span>
                  <span>{`До ${productAmount.max} ${productAmount.productVolume}`}</span>
                </>
              )}
            </span>
          )}
          <div className={styles.card__buttons}>
            <CustomButton
              title="Добавить в корзину"
              onClick={onOpenSelectOfferModal}
            />
          </div>

          {showSelectOfferModal && (
            <ProductCardOffersModal
              logoSrc={product.reducedImage?.link ?? noImageLink}
              title={product.name}
              productDescription={productMinDescription}
              offers={product.offers}
              productUrl={productUrl}
              modalRef={modalRef}
              onClose={onCloseSelectOfferModal}
              productItem={product}
            />
          )}
        </Link>
        <LinkToButton />
      </div>
    </>
  );
};

export default ProductCard;

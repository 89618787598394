import React from "react";

const useDidUpdate = (cb: () => void, deps: React.DependencyList) => {
  const isInit = React.useRef<boolean>(true);

  React.useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      return;
    }
    cb();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};

export default useDidUpdate;

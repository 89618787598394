import styles from "./styles.module.scss";
import { PropTypes } from "prop-types";
import cn from "classnames";

export const SkeletonImg = ({ style, className }) => {
  return (
    <>
      <div className={cn(styles.avatar, className)} style={style} />
    </>
  );
};

const propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
};

SkeletonImg.propTypes = propTypes;

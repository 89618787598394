import cn from "classnames";
import styles from "./styles.module.scss";

interface BurgerButtonProps {
  onClick: () => void;
  containerStyles?: string;
}

const BurgerButton: React.FC<BurgerButtonProps> = (props) => {
  return (
    <button
      className={cn(styles.root, props.containerStyles)}
      onClick={props.onClick}
    >
      {Array(3)
        .fill(1)
        .map((_, idx) => {
          return <div key={idx} className={styles.line} />;
        })}
    </button>
  );
};

export default BurgerButton;

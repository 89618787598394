import { useState, useEffect, useMemo } from "react";
import { useAppSelector } from "store/reduxHooks";
import cn from "classnames";

import { productsApi } from "../../../../api/productsApi";

import CustomLink from "../../../../components/CustomLink/CustomLink";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import MainSlider from "../../../../components/MainSlider/MainSlider";
import ProductCard from "../../../../components/ProductCard/ProductCard";
import { CustomError, getErrorData } from "utils/getErrorData";
import { isError } from "store/user/userThunks/userThunks";

import { IProductWithOffers } from "types/IProduct";

import styles from "./styles.module.scss";

interface ITabItem {
  id: string;
  title: string;
  values: Record<string, string>;
}

export const SELECT_TYPE_TABS: ITabItem[] = [
  {
    id: "1",
    title: "Парфюмерия",
    values: {
      categories: "parfumeria"
    }
  },
  {
    id: "2",
    title: "Для волос",
    values: {
      categories: "kosmetika",
      "properties[kategoria]": "dla-volos"
    }
  },
  {
    id: "3",
    title: "Для тела",
    values: {
      categories: "kosmetika",
      "properties[kategoria]": "dla-tela"
    }
  },
  {
    id: "4",
    title: "Для лица",
    values: {
      categories: "kosmetika",
      "properties[kategoria]": "dla-lica"
    }
  }
];

const DiscountsBlock: React.FC = () => {
  const { deviceType } = useAppSelector((state) => state.user);

  const [products, setProducts] = useState<IProductWithOffers[]>([]);
  const [activeTabId, setActiveTabId] = useState(SELECT_TYPE_TABS[0].id);
  const [isOffersModalOpened, setIsOffersModalOpened] = useState(false);

  const slidesToScroll = useMemo(() => {
    if (deviceType.isMobile) {
      return 2;
    }
    return deviceType.isDesktop || deviceType.isLargeDesktop ? 4 : 3;
  }, [deviceType]);

  useEffect(() => {
    (async () => {
      try {
        const currentTab = SELECT_TYPE_TABS.find(
          (item) => item.id === activeTabId
        );

        const data = await productsApi.getProductsWithLimit({
          limit: "16",
          badges: "akcia,sale",
          ...currentTab.values
        });

        if (isError(data)) {
          const errorData = getErrorData(data);
          throw new CustomError(errorData.message, errorData?.code);
        }

        setProducts(data.response.items ?? []);
      } catch (err) {
        const errorData = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error(
          "Ошибка при получении товаров в разделе Скидки: -->",
          errorData.message
        );
      }
    })();
  }, [activeTabId]);

  const onClickSelectTab = (id: string) => {
    setActiveTabId(id);
  };

  return (
    <section className={styles.root}>
      <SectionTitle
        title={
          !deviceType.isMobile ? "Скидки: наши акции и распродажи" : "Скидки"
        }
      />
      <div className={styles["slider-wrapper"]}>
        {!!products.length && (
          <MainSlider
            onClickSelectTab={onClickSelectTab}
            activeTabId={activeTabId}
            slidesSpacing={!deviceType.isMobile ? 150 : undefined}
            sliderContainerStyles={styles.slider}
            contentStyles={styles.content}
            paginationStyles={styles.pagination}
            dotsStyles={styles.dots}
            shouldAutoSwitched={!isOffersModalOpened}
            shouldBeLooped
            shouldControlAutoSwitched
          >
            {Array.from(
              { length: Math.ceil(products.length / slidesToScroll) },
              (_, i) => (
                <div
                  className={cn(styles["grouped-cards"], "keen-slider__slide")}
                  key={`row${i}`}
                >
                  {products
                    .slice(i * slidesToScroll, (i + 1) * slidesToScroll)
                    .map((product) => {
                      return (
                        <div
                          key={product.id}
                          className={styles["card-wrapper"]}
                        >
                          <ProductCard
                            product={product}
                            key={product.id}
                            onToggleIsOffersModalOpenedStatus={setIsOffersModalOpened}
                          />
                        </div>
                      );
                    })}
                </div>
              )
            )}
          </MainSlider>
        )}
      </div>
      <CustomLink
        path="/catalog?badges=akcia,sale"
        title="Смотреть все"
        className={styles["see-more"]}
      />
    </section>
  );
};

export default DiscountsBlock;

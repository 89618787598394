import { useMemo, useState } from "react";
import Cosmetics from "../../../../assets/icons/svg/cosmetics.svg";
import ProductDescription from "./components/ProductDescription/ProductDescription";
import ProductOffers from "./components/ProductOffers/ProductOffers";
import { SkeletonProductDescription } from "../../../../components/UI/Skeleton/SkeletonProductDescription/SkeletonProductDescription";
import { SkeletonLine } from "../../../../components/UI/Skeleton/SkeletonLine/SkeletonLine";
import cn from "classnames";

import styles from "./styles.module.scss";
import { IProduct } from "types/IProduct";
import { IOffer, IProperty } from "types/IOffer";

interface IProductMainContentProps {
  isLoading: boolean;
  article?: string;
  product?: IProduct;
  offers?: IOffer[];
  properties?: IProperty[];
  scrollToReviews: () => void;
  setIsOpenRedirectModalCart: (isOpen: boolean) => void;
  name: string;
  type: string;
}

const ProductMainContent: React.FC<IProductMainContentProps> = (props) => {
  const [loadImg, setLoadImg] = useState(false);
  const noImageLink = useMemo(() => {
    return props.product?.category.rewrite_name === "kosmetika"
      ?
      "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg" :
      "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [props.product?.category.rewrite_name]);

  const mainSrc = !props.isLoading && props.product?.image?.link ? props.product?.image?.link : noImageLink;
  const isCosmetic = props.product?.category.rewrite_name === "kosmetika";

  return (
    <div className={styles.root}>
      <h1 className={styles.seo}>
        {props.name} {isCosmetic ? "" : props.type}
      </h1>
      <div className={styles["preview-wrapper"]}>
        <div className={styles.preview}>
          <div className={styles["preview_img-wrapper"]}>
            {(props.isLoading || !loadImg) && (
              <img
                src={Cosmetics}
                className={styles["preview_img-wrapper-svg"]}
              />
            )}

            {!props.isLoading && (
              <img
                itemProp="image"
                src={mainSrc}
                alt={props.product?.product_rewrite_name}
                className={cn(
                  styles["preview_img-wrapper-img"],
                  {
                    [styles["preview_img-wrapper-disabled"]]:
                      props.isLoading || !loadImg,
                  },
                  {
                    [styles["preview_img-wrapper-no-img"]]:
                      !props.product?.image?.link,
                  }
                )}
                onLoad={() => setLoadImg(true)}
              />
            )}
          </div>
          {props.isLoading || !props.product ? (
            <SkeletonProductDescription />
          ) : (
            <ProductDescription
              brandDescription={props.product?.brands[0]?.brand?.description}
              brandTitle={props.product?.brands[0].brand.name}
              brandRewriteName={props.product?.brands[0].brand.rewrite_name}
              productTitle={props.product?.name}
              description={props.product?.description}
              productRewrateName={props.product?.product_rewrite_name}
              category={props.product?.category}
              properties={props.properties}
              offers={props.offers}
              scrollToReviews={props.scrollToReviews}
            />
          )}
        </div>
        <div className={styles["offers-wrapper"]}>
          <div className={styles["offers-wrapper-article"]}>
            {!props.isLoading && props.article ? (
              `Артикул: ${props.article}`
            ) : (
              <SkeletonLine />
            )}
          </div>
          <ProductOffers
            offers={props.offers}
            product={props.product}
            setIsOpenRedirectModalCart={props.setIsOpenRedirectModalCart}
            isLoading={props.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductMainContent;

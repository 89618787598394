// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIsEqual = <T extends Record<string, any>>(
  x: T | null | undefined,
  y: T | null | undefined
): boolean => {
  const ok = Object.keys;
  const tx = typeof x;
  const ty = typeof y;

  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => getIsEqual(x[key], y[key]))
    : x === y;
};

import CustomButton from "../CustomButton/CustomButton";

import cn from "classnames";
import styles from "./styles.module.scss";
import { setUserCityStorage } from "../../utils/localStorage";

interface CitiesSuggestionContentProps {
  cityTitle: string;
  containerStyles?: string;
  contentRef?: React.Ref<HTMLDivElement>;
  onClose: () => void;
  onShowSelectCities: () => void;
  disableShadow?: boolean;
}

const CitiesSuggestionContent: React.FC<CitiesSuggestionContentProps> = props => {
  const saveCity = () => {
    setUserCityStorage(props.cityTitle);
    props.onClose();
  };

  return (
    <div
      className={cn(styles.root, props.containerStyles, {
        [styles["root--shadow-disabled"]]: props.disableShadow,
      })}
      ref={props.contentRef}
    >
      <p className={styles.header}>
        <span className={styles.header_title}>Ваш населённый пункт:</span>
        <span className={styles["header_city-title"]}>{props.cityTitle}?</span>
      </p>
      <div className={styles.buttons}>
        <CustomButton title='Да, верно' onClick={() => saveCity()} />
        <CustomButton
          title='Нет, другой'
          isWhite
          onClick={props.onShowSelectCities}
        />
      </div>
    </div>
  );
};

export default CitiesSuggestionContent;

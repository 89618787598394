import SingleImageLoader from "components/UI/SingleImageLoader/SingleImageLoader";
import styles from "./styles.module.scss";

interface BestsellersCategoryImageProps {
  imageUrl: string;
}

const BestsellersCategoryImage: React.FC<BestsellersCategoryImageProps> = ({ imageUrl }) => {

  return (
    <div className={styles.wrapper}>
      <SingleImageLoader src={imageUrl} alt="Категория товаров" className={styles.image} />
    </div>
  );
};

export default BestsellersCategoryImage;
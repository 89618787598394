export const getResultStringToSaveInQuery = ({
  newItem,
  currentQuery
}: {
  newItem: string;
  currentQuery: string;
}) => {
  if (!currentQuery) {
    return newItem;
  }

  const isIncluded = currentQuery.includes(newItem);
  const queryArray = currentQuery.split(",");

  if (isIncluded) {
    return queryArray.filter((item) => item !== newItem).join(",");
  }

  queryArray.push(newItem);
  return queryArray.join(",");
};

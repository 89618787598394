import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles.module.scss";

interface LandingPageProps {
  content: React.ReactNode;
  pageName: string;
}

const LandingPage: React.FC<LandingPageProps> = ({ content, pageName }) => {
  const { pageName: paramPageName } = useParams<{ pageName: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const currentUrl = `/page/${paramPageName}`;
    const expectedUrl = `/page/${pageName}`;

    if (currentUrl !== expectedUrl) {
      navigate(expectedUrl, { replace: true });
    }
  }, [paramPageName, pageName, navigate]);

  return (
    <main className={styles.root}>
      {content}
    </main>
  );
};

export default LandingPage;
import cn from "classnames";
import styles from "./styles.module.scss";

interface SectionTitleProps {
  title: string;
  containerStyles?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  return (
    <h2 className={cn(styles.title, props.containerStyles)}>{props.title}</h2>
  );
};

export default SectionTitle;

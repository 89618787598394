import { useMemo, useState } from "react";
import cn from "classnames";

import MainSlider from "../../../../components/MainSlider/MainSlider";
import ProductCard from "../../../../components/ProductCard/ProductCard";
import { IProductWithOffers } from "types/IProduct";

import styles from "./styles.module.scss";

interface GeneralProductsSliderProps {
  products: IProductWithOffers[];
  isMobile: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  shouldAutoSwitched?: boolean;
  shouldBeLooped?: boolean;
}

const GeneralProductsSlider: React.FC<GeneralProductsSliderProps> = (props) => {
  const [isOffersModalOpened, setIsOffersModalOpened] = useState(false);

  const slidesToScroll = useMemo(() => {
    if (props.isMobile) {
      return 2;
    }
    return props.isDesktop || props.isLargeDesktop ? 4 : 3;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isMobile, props.isDesktop]);

  return (
    <div className={styles["slider-wrapper"]}>
      <MainSlider
        slidesSpacing={!props.isMobile ? 150 : undefined}
        sliderContainerStyles={styles.slider}
        contentStyles={styles.content}
        paginationStyles={styles.pagination}
        dotsStyles={styles.dots}
        shouldControlAutoSwitched
        shouldAutoSwitched={props.shouldAutoSwitched && !isOffersModalOpened}
        shouldBeLooped={props.shouldBeLooped}
      >
        {Array.from(
          { length: Math.ceil(props.products.length / slidesToScroll) },
          (_, i) => (
            <div
              className={cn(styles["grouped-cards"], "keen-slider__slide")}
              key={`row${i}`}
            >
              {props.products
                .slice(i * slidesToScroll, (i + 1) * slidesToScroll)
                .map((product) => {
                  return (
                    <div key={product.id} className={styles["card-wrapper"]}>
                      <ProductCard
                        product={product}
                        key={product.id}
                        onToggleIsOffersModalOpenedStatus={
                          setIsOffersModalOpened
                        }
                      />
                    </div>
                  );
                })}
            </div>
          )
        )}
      </MainSlider>
    </div>
  );
};

export default GeneralProductsSlider;

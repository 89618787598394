/* eslint-disable no-console */
import { useEffect, useMemo, useRef, useState } from "react";

import getSortedOffersByPriceAndVolume from "../../../../../../utils/getSortedOffersByPriceAndVolume";
import { addProductToCart } from "../../../../../../store/user/cartThunks/cartThunks";
import saleTicketIcon from "../../../../../../assets/icons/png/sale_ticket-icon.png";

import ProductOffersHeader from "../../../ProductOffersHeader/ProductOffersHeader";
import DolyamiDropDown from "../DropDowns/DolyamiDropDown/DolyamiDropDown";
import CostCalculationDropDown from "../DropDowns/CostCalculationDropDown/CostCalculationDropDown";
import HelpDropDown from "../DropDowns/HelpDropDown/HelpDropDown";
import OffersList from "./OffersList/OffersList";

import ModalFoundCheaper from "../../../../../../components/UI/ModalFoundCheaper/ModalFoundCheaper";
import cn from "classnames";
import useSearchParamsHandler from "../../../../../../utils/hooks/useSearchParamsHandler";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { metric } from "../../../../../../utils/yandexMetrics/yandexMetrics";
import { IProduct } from "types/IProduct";
import { IOffer } from "types/IOffer";
import { formatPrice } from "utils/helpers/formatedPrice";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";

import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";
import { IEcommerceYandex } from "types/IEcommerceYandex";
import { ICurrentOffer } from "types/ICurrentOffer";

export interface IProductOffersProps {
  setIsOpenRedirectModalCart: (isOpen: boolean) => void;
  offers: IOffer[];
  product: IProduct;
  isLoading: boolean;
}


export const ProductOffers: React.FC<IProductOffersProps> = (props) => {
  const { cart } = useAppSelector((state) => state.user);
  const [isModalCheaper, setIsModalCheaper] = useState(false);
  const dispatch = useAppDispatch();
  const { searchParams } = useSearchParamsHandler();
  const { type } = Object.fromEntries(searchParams);
  const [, setSearchParams] = useSearchParams();


  const sortedOffers = useMemo(() => {
    if (props.isLoading || !props.product) return [];
    return getSortedOffersByPriceAndVolume(props.offers);
  }, [props.offers, props.isLoading, props.product]);

  const [currentOffer, setCurrentOffer] = useState<ICurrentOffer>({
    id: null,
    description: "",
    promotionPrice: null,
    price: null,
    percent: null,
    available: null,
  });
  const modalCheaperRef = useRef<HTMLDivElement | null>(null);

  const onSelectOffer = (options: ICurrentOffer) => {
    setCurrentOffer(options);
    setSearchParams({ type: options.id.toString() });
  };

  const wordsArr = props.product?.name.split(" ");
  const fiveWords = wordsArr?.splice(0, 5);
  const text = fiveWords?.length <= wordsArr?.length ? fiveWords?.join(" ") : fiveWords?.join(" ") + "...";


  // yandex ecommerce 'add'
  const yandexEcommerceAdd = async (id: number) => {

    const selectedOffer = sortedOffers.find(offer => offer.id === id);

    if (!selectedOffer) {
      return;
    }

    const productVariantString = `${selectedOffer.name}, ID: ${selectedOffer.id}`;
    const ecommerceAddData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.add]: {
          products: [
            {
              id: props.product.id?.toString(),
              name: props.product.name,
              category: props.product.category.name,
              brand: (props.product.brands && !!props.product.brands.length) ? props.product.brands[0].brand.name : "",
              price: currentOffer.promotionPrice ?? currentOffer.price,
              quantity: 1,
              variant: productVariantString
            }
          ]
        }
      }
    };

    await handleYandexEcommerce(ecommerceAddData);
  };

  const addProduct = async () => {
    if (process.env.REACT_APP_REDIRECT_CART === "true") return props.setIsOpenRedirectModalCart(true);
    if (!props.product || (!currentOffer.promotionPrice && !currentOffer.price)) {
      return;
    }

    try {
      if (!currentOffer.available) {
        toast.info("На данный момент этого товара нет в наличии.");
        return;
      }
      const options = {
        id: currentOffer.id,
        count: 1
      };
      dispatch(addProductToCart([options])).unwrap();
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };
  const productInCart = useMemo(() => cart.find(e => e.offer.id === currentOffer.id), [cart, currentOffer]);

  const addProductAmount = async () => {
    try {
      if (!currentOffer.available) {
        toast.info("На данный момент этого товара нет в наличии.");
        return;
      }
      const options = {
        id: currentOffer.id,
        count: productInCart.count + 1,
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };

  useEffect(() => {
    if (props.isLoading) return;
    const filteredOffered = sortedOffers.filter(offer => offer.id === Number(type));

    if (filteredOffered[0]) {
      return setCurrentOffer({
        id: filteredOffered[0]?.id || null,
        description: filteredOffered[0]?.name ?? "",
        promotionPrice: filteredOffered[0]?.price_sale ?? filteredOffered[0]?.akcia ?? null,
        price: filteredOffered[0]?.price ?? null,
        percent: filteredOffered[0]?.percent ?? null,
        available: sortedOffers[0]?.available ?? null,
      });
    }

    setCurrentOffer({
      id: sortedOffers[0]?.id || null,
      description: sortedOffers[0]?.name ?? "",
      promotionPrice: sortedOffers[0]?.price_sale ?? sortedOffers[0]?.akcia ?? null,
      price: sortedOffers[0]?.price ?? null,
      percent: sortedOffers[0]?.percent ?? null,
      available: sortedOffers[0]?.available ?? null,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedOffers, type]);


  return (
    <div className={styles.root}>
      <ProductOffersHeader
        brandRewriteName={props.product?.brands[0].brand.rewrite_name}
        brandName={props.product?.brands[0].brand.name}
        productName={props.product?.name}
        currentOffer={currentOffer}
        isLoading={props.isLoading}
      />
      {!!sortedOffers.length && (
        <span className={styles["product_select-title"]}>
          Выберите вариант товара
        </span>
      )}
      <OffersList
        productId={props.product?.id}
        sortedOffers={sortedOffers}
        activeOfferId={currentOffer.id}
        onSelectOffer={onSelectOffer}
      />
      <div className={styles["product__buttons"]} itemScope itemType="https://schema.org/Offer">
        {currentOffer.available ? (
          <meta itemProp="availability" content="https://schema.org/InStock" />
        ) : (
          <meta itemProp="availability" content="https://schema.org/OutOfStock" />
        )}
        {!!sortedOffers.length && (
          <button className={styles["product__buttons__cheap"]} onClick={() => setIsModalCheaper(true)}>
            <img
              src={saleTicketIcon}
              alt="sale_ticket-icon"
              className={styles["product__buttons__cheap_icon"]}
            />
            <span className={styles["product__buttons__cheap_title"]}>
              Нашли дешевле? Сообщите нам!
            </span>
          </button>
        )}
        {!currentOffer.available && <span className={styles["product__available"]}>Товара нет в наличии</span>}
        <button
          className={cn(
            styles["product__buttons__cart"],
            { [styles["product__buttons__cart-disabled"]]: props.isLoading || !currentOffer.available }
          )}
          onClick={!productInCart ? addProduct : addProductAmount}
          disabled={props.isLoading || !currentOffer.available}
        >
          {productInCart ?
            <span className={styles["product__buttons__cart--title"]}>Добавить ещё</span> :
            <span className={styles["product__buttons__cart--title"]}>Добавить в корзину</span>
          }
          <span className={styles["product__buttons__cart_price"]}>
            {!currentOffer.promotionPrice
              ? !!currentOffer.price && `${currentOffer.price} ₽`
              : <>
                <span className={styles["product__buttons__cart_price--promo"]}>
                  {`${formatPrice(currentOffer.promotionPrice)} ₽ `}
                </span>
                <span className={styles["product__buttons__cart_price--main"]}>
                  {`${formatPrice(currentOffer.price)} ₽ `}
                </span>
              </>
            }
          </span>
        </button>
      </div>
      {process.env.REACT_APP_COMPONENT_ENABLE_DOLYAMI_DROP_DOWN === "true" && <DolyamiDropDown price={currentOffer.promotionPrice ?? currentOffer.price} />}
      {!!sortedOffers.length && <CostCalculationDropDown price={currentOffer.promotionPrice ?? currentOffer.price} isLoading={props.isLoading} />}
      <HelpDropDown isLoading={props.isLoading} />
      {!props.isLoading && isModalCheaper && <ModalFoundCheaper currentOfferId={currentOffer.id} onClose={() => setIsModalCheaper(false)} modalRef={modalCheaperRef} />}
    </div>
  );
};

export default ProductOffers;
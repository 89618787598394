import { IProductWithOffers } from "types/IProduct";
import BestsellersProductCard from "../BestsellersProductCard/BestsellersProductCard";
import ViewAllLink from "./components/ViewAllLink";
import styles from "./styles.module.scss";
import { useAppSelector } from "store/reduxHooks";
import SkeletonBestsellerCard from "components/UI/Skeleton/SkeletonBestsellerCard/SkeletonBestsellerCard";
import { useEffect, useState } from "react";


interface BestsellersProductsListProps {
  products: IProductWithOffers[];
  link: string;
  isLoading: boolean;
}

const BestsellersProfitBuyBlock: React.FC<BestsellersProductsListProps> = ({ products, link, isLoading }) => {
  const { deviceType } = useAppSelector((state) => state.user);
  const [displayedProducts, setDisplayedProducts] = useState(products);
  const [skeletonCount, setSkeletonCount] = useState(products.length);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 700px) and (max-width: 809px)").matches) {
        setDisplayedProducts(products.slice(0, 4));
        setSkeletonCount(4);
      } else if (window.matchMedia("(min-width: 1320px) and (max-width: 1519px)").matches) {
        setDisplayedProducts(products.slice(0, 5));
        setSkeletonCount(5);
      } else if (deviceType.isTablet) {
        setDisplayedProducts(products.slice(0, 3));
        setSkeletonCount(3);
      } else if (deviceType.isDesktop) {
        setDisplayedProducts(products.slice(0, 4));
        setSkeletonCount(4);
      } else {
        setDisplayedProducts(products);
        setSkeletonCount(products.length);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [deviceType, products]);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Покупайте с выгодой</h2>
      <div className={styles.products}>
        {isLoading ? (
          Array.from({ length: skeletonCount }).map((_, index) => (
            <SkeletonBestsellerCard key={index} />
          ))
        ) : (
          displayedProducts.map((product) => (
            <BestsellersProductCard key={product.id} product={product} />
          ))
        )}
      </div>
      <ViewAllLink isLoading={isLoading}  link={link} />
    </div>
  );
};

export default BestsellersProfitBuyBlock;
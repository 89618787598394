import { useAppSelector } from "store/reduxHooks";

import cn from "classnames";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import { SkeletonLine } from "../../../../components/UI/Skeleton/SkeletonLine/SkeletonLine";
import { formatPrice } from "utils/helpers/formatedPrice";
import { ICurrentOffer } from "types/ICurrentOffer";

interface ProductOffersHeaderProps {
  isLoading: boolean;
  brandName: string;
  productName: string;
  currentOffer: ICurrentOffer;
  brandRewriteName: string;
}

const ProductOffersHeader: React.FC<ProductOffersHeaderProps> = (props) => {
  const { deviceType } = useAppSelector((state) => state.user);

  const link = `/catalog/?brands=${props.brandRewriteName}`;

  return (
    <>
      {!deviceType.isMobile && (
        <h3>
          <NavLink
            to={link}
            className={styles["brand-title"]}
          >
            {props.brandName}
          </NavLink>
        </h3>
      )}

      <h4
        className={cn(styles["main-title"], {
          [styles["main-title--colored"]]: deviceType.isMobile,
        })}
      >
        {props.isLoading ? (
          <>
            <SkeletonLine className={styles["main-title-skeleton"]} />
            <SkeletonLine className={styles["main-title-skeleton"]} />
            <SkeletonLine />
          </>
        ) : <span itemProp="name">{props.productName}</span>}
      </h4>

      <p className={styles["description"]}>{props.currentOffer.description}</p>
      {process.env.REACT_APP_COMPONENT_DELIVERY_DATE === "true" && <p className={styles["delivery-date"]}>Дата получения 3-5 марта</p>}

      <div
        className={styles["promotion-content"]}
        itemProp="offers"
        itemScope
        itemType="https://schema.org/Offer"
      >
        {!props.isLoading ? (
          !!props.currentOffer.promotionPrice && (
            <span className={styles["promotion-content__price-promo"]}>
              <span
                itemProp="price"
                data-content={props.currentOffer.promotionPrice}
              >
                {formatPrice(props.currentOffer.promotionPrice)}
              </span>
              <span itemProp="priceCurrency" content="RUB"> ₽ </span>
            </span>
          )) : (
          <SkeletonLine className={styles["promotion-content__price-promo-skeleton"]} />
        )}

        {!props.isLoading ?
          !props.currentOffer.price ? (
            <span
              className={cn(styles["promotion-content__price-main"], {
                [styles["promotion-content__price-main--available"]]:
                  !props.currentOffer.promotionPrice,
              })}
            >
              Цена недоступна
            </span>
          ) : (
            <span
              className={cn(styles["promotion-content__price-main"], {
                [styles["promotion-content__price-main--available"]]:
                  !props.currentOffer.promotionPrice,
              })}
            >
              {`${formatPrice(props.currentOffer.price)} ₽`}
            </span>
          )
          :
          <SkeletonLine className={styles["promotion-content__price-promo-skeleton"]} />
        }

        {!!props.currentOffer.percent && (
          <span className={styles["promotion-content__percent"]}>
            {`${props.currentOffer.percent}%`}
          </span>
        )}
      </div>
    </>
  );
};

export default ProductOffersHeader;

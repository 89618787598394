import * as yup from "yup";

export const foundCheaperShema = yup.object().shape({
  comment: yup
    .string()
    .required("Это поле обязательно"),
  login: yup
    .string()
    .required("Это поле обязательно")
    .test("is-email-or-phone", "Введите корректный email или номер телефона", value => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^\+?[0-9()\-\s]{10,20}$/;
      const isValidEmail = emailPattern.test(value) && value.length <= 40;
      const isValidPhone = phonePattern.test(value) && value.replace(/\D/g, "").length === 11;
      return isValidEmail || isValidPhone;
    }),
  name: yup
    .string()
    .required("Это поле обязательно")
    .test("not-only-spaces", "Имя не может состоять только из пробелов", value => value && value.trim().length > 0),
});

import ProgressiveImage from "components/ProgressiveImage/ProgressiveImage";
import cn from "classnames";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";

interface SingleImageLoaderProps {
  src: string;
  alt: string;
  className?: string;
}

const SingleImageLoader: React.FC<SingleImageLoaderProps> = ({ src, alt, className }) => {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!src) {
      // eslint-disable-next-line no-console
      console.error("URL изображения не указан");
      return;
    }
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setIsLoaded(true);
    };
    img.onerror = () => {
      // eslint-disable-next-line no-console
      console.error("При загрузке изображения произошла ошибка URL:", src);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src]);

  return (
    <ProgressiveImage
      highQualitySrc={src}
      alt={alt}
      className={cn(className, styles.image, {
        [styles.loading]: !isLoaded,
        [styles.loaded]: isLoaded,
      })}
    />
  );
};

export default SingleImageLoader;
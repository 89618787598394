/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO - когда будет больше времени попробовать расширить тип хуков слайдера для "resumed" и "stopped"
import { useEffect } from "react";
import "keen-slider/keen-slider.min.css";
import cn from "classnames";

import useSlider from "../../utils/hooks/useSlider";
import useIsFirstRender from "../../utils/hooks/useIsFirstRender";

import CustomArrowButton from "components/CustomArrowButton/CustomArrowButton";

import SliderTab from "./components/SliderTab/SliderTab";

import styles from "./styles.module.scss";

interface MainSliderProps {
  onClickSelectTab?: (tabId: string) => void;
  activeTabId?: string;
  children: React.ReactNode;
  sliderContainerStyles?: string;
  contentStyles?: string;
  paginationStyles?: string;
  dotsStyles?: string;
  initialSlide?: number;
  shouldBeLooped?: boolean;
  shouldAutoSwitched?: boolean;
  hidePagination?: boolean;
  hideDots?: boolean;
  hideArrowButtons?: boolean;
  slidesPerView?: number;
  slidesSpacing?: number;
  shouldControlAutoSwitched?: boolean;
}

const MainSlider: React.FC<MainSliderProps> = (props) => {
  const {
    currentSlide,
    isLoaded,
    availableDots,
    sliderRef,
    instanceRef,
    onClickPrev,
    onClickNext
  } = useSlider({
    initialSlide: props.initialSlide,
    shouldBeLooped: props.shouldBeLooped,
    slidesPerView: props.slidesPerView,
    slidesSpacing: props.slidesSpacing,
    shouldAutoSwitched: props.shouldAutoSwitched
  });

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    instanceRef.current?.moveToIdx(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTabId]);

  useEffect(() => {
    if (!props.shouldControlAutoSwitched || isFirstRender) {
      return;
    }

    if (props.shouldAutoSwitched) {
      (instanceRef.current as any)?.emit("resumed");
      return;
    }
    (instanceRef.current as any)?.emit("stopped");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shouldControlAutoSwitched, props.shouldAutoSwitched]);

  const handleResumed = () => {
    (instanceRef.current as any)?.emit("resumed");
  };
  const handleStopped = () => {
    (instanceRef.current as any)?.emit("stopped");
  };
  return (
    <>
      {props.activeTabId && (
        <SliderTab
          onClickSelectTab={props.onClickSelectTab}
          activeTabId={props.activeTabId}
          handleResumed={handleResumed}
          handleStopped={handleStopped}
        />
      )}
      <div className={cn(styles.root, props.sliderContainerStyles)}>
        <div ref={sliderRef} className={cn("keen-slider", props.contentStyles)}>
          {props.children}
        </div>
        {!props.hidePagination && isLoaded && instanceRef.current && (
          <div className={cn(styles.pagination, props.paginationStyles)}>
            {!props.hideArrowButtons && (
              <CustomArrowButton diraction="left" onClick={onClickPrev} />
            )}
            {!props.hideDots && (
              <div className={cn(styles.dots, props.dotsStyles)}>
                {availableDots.map((idx) => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx);
                        (instanceRef.current as any)?.emit("stopped");
                      }}
                      className={cn(styles.dot, {
                        [styles["dot--active"]]: currentSlide === idx
                      })}
                      onMouseUp={() =>
                        (instanceRef.current as any)?.emit("resumed")
                      }
                      onMouseLeave={() =>
                        (instanceRef.current as any)?.emit("resumed")
                      }
                    ></button>
                  );
                })}
              </div>
            )}
            {!props.hideArrowButtons && (
              <CustomArrowButton diraction="right" onClick={onClickNext} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MainSlider;

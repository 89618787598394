import { useAppSelector } from "store/reduxHooks";

import CustomButton from "../CustomButton/CustomButton";
import userAvatar from "../../assets/icons/png/user_avatar.png";

import cn from "classnames";
import styles from "./styles.module.scss";

interface ExitFromPersonalAreaContentProps {
  containerStyles?: string;
  contentRef?: React.Ref<HTMLDivElement> | null;
  onClose: (args: { isExit: boolean }) => void;
}

const ExitFromPersonalAreaContent: React.FC<ExitFromPersonalAreaContentProps> = (props) => {
  const { user } = useAppSelector((state) => state.user);

  return (
    <div
      ref={props.contentRef}
      className={cn(styles.root, props.containerStyles)}
    >
      <img src={userAvatar} alt="user_avatar" className={styles.avatar} />
      <span className={styles["user-data"]}>
        <span>{!user.surname ? "" : `${user.surname} `}</span>
        <span>{!user.name ? "" : user.name}</span>
        <span>{!user.patronymic ? "" : ` ${user.patronymic}`}</span>
      </span>
      <span className={styles.title}>Выйти из аккаунта?</span>
      <div className={styles["buttons-wrapper"]}>
        <CustomButton
          title="Выйти"
          isWhite
          onClick={() => {
            props.onClose({ isExit: true });
          }}
          className={styles.button}
        />
        <CustomButton
          title="Остаться"
          onClick={() => props.onClose({ isExit: false })}
          className={styles.button}
        />
      </div>
    </div>
  );
};

export default ExitFromPersonalAreaContent;

import close from "../../../assets/icons/svg/cross-rounded.svg";
import logo from "../../../assets/parfumart_logo.png";
import styles from "./styles.module.scss";

import cn from "classnames";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { foundCheaperShema } from "../../../schemas/foundCheaperShema";
import CustomButton from "../../CustomButton/CustomButton";
import { cheaperApi } from "../../../api/cheaperApi";
import { getUserToken, setTokens } from "../../../utils/localStorage";
import { userApi } from "../../../api/userApi";
import { useAppSelector } from "store/reduxHooks";

interface IContentFoundCheaperProps {
  onClose: () => void;
  modalRef?: React.RefObject<HTMLDivElement> | null;
  currentOfferId: number;
}

const ContentFoundCheaper: React.FC<IContentFoundCheaperProps> = (props) => {
  const { user } = useAppSelector((state) => state.user);


  const formik = useFormik({
    initialValues: {
      comment: "",
      login: user.email || (user.phone ? `+${user.phone}` : ""),
      name: user.name || "",
      offerId: props.currentOfferId
    },
    validationSchema: foundCheaperShema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const token = getUserToken();
        const order = {
          ...values,
          id: values.offerId
        };
        if (token) {
          await cheaperApi.sendPrivateCheaperOrder(order);
        } else {
          await cheaperApi.sendPublicCheaperOrder(order);
        }
        await props.onClose();
        toast("Спасибо за ваше сообщение! Мы обязательно свяжемся с вами.");
      } catch (err) {

        if (err instanceof Error && err.message === "401") {
          const refreshData = await userApi.refreshUser();

          if ("response" in refreshData && refreshData.response) {
            setTokens({
              token: refreshData.response.token,
              refreshToken: refreshData.response.refresh,
            });

            try {
              await cheaperApi.sendPrivateCheaperOrder(values);
              await props.onClose();
              toast("Спасибо за ваше сообщение! Мы обязательно свяжемся с вами.");
            } catch (error) {
              toast("Что-то пошло не так. Попробуйте еще раз.");
            }
          }
          return;
        }

        if (err instanceof Error && err.message) {
          toast(err.message);
          return;
        }

        toast("Что-то пошло не так. Попробуйте еще раз.");

      } finally {
        setSubmitting(false);
      }
    },
    validateOnBlur: false
  });

  function formatPhoneNumber(value: string) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      return value;
    } else if (value.includes("@")) {
      return value.replace(/^\+/, "");
    } else if (!value.startsWith("+") && !emailPattern.test(value)) {
      return `+${value}`;
    } else {
      return value;
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    if (name === "login") {
      const formattedValue = formatPhoneNumber(value);
      formik.setFieldValue(name, formattedValue, false);
      formik.setFieldTouched(name, true, false);
    }
  }

  return (
    <div className={styles.modal__container} ref={props.modalRef}>
      <div className={styles["modal__container_logo-wrapper"]}>
        <img src={logo} className={styles["modal__container_logo-wrapper-logo"]} />
        <button className={styles["modal__container_logo-wrapper_btn"]} onClick={() => props.onClose()}>
          <img src={close} className={styles["modal__container_logo-wrapper_btn-close"]} />
        </button>
      </div>
      <div className={styles["modal__container_heading-wrapper"]}>
        <h1 className={styles["modal__container_heading-wrapper-heading"]}>Нашли дешевле? Сообщите нам!</h1>
      </div>
      <div className={styles["modal__container_input-wrapper"]}>
        <p className={styles["modal__container_input-wrapper-form-error"]}>{formik.errors.comment}</p>
        <textarea
          placeholder="Ссылка на страницу и комментарий"
          value={formik.values.comment}
          name="comment"
          onChange={e => formik.handleChange(e)}
          className={cn(
            styles["modal__container_input-wrapper-input"],
            styles["modal__container_input-wrapper-comment"],
            { [styles["modal__container_input-wrapper-error"]]: formik.errors.comment },
          )}
        />
        <p className={styles["modal__container_input-wrapper-form-error"]}>{formik.errors.login}</p>
        <input
          placeholder="Ваш номер телефона или email"
          value={formik.values.login}
          name="login"
          onBlur={handleBlur}
          onChange={e => formik.handleChange(e)}
          className={cn(
            styles["modal__container_input-wrapper-input"],
            { [styles["modal__container_input-wrapper-error"]]: formik.errors.login },
          )}
        />
        <p className={styles["modal__container_input-wrapper-form-error"]}>{formik.errors.name}</p>
        <input
          placeholder="Ваше имя"
          value={formik.values.name}
          name="name"
          onChange={e => formik.handleChange(e)}
          className={cn(
            styles["modal__container_input-wrapper-input"],
            { [styles["modal__container_input-wrapper-error"]]: formik.errors.name },
          )}
        />
      </div>
      <CustomButton
        title="Отправить"
        className={styles["modal__container-btn-send"]}
        isDisabled={!!formik.errors.comment || !!formik.errors.login || !!formik.errors.name}
        onClick={formik.submitForm}
      />
    </div>
  );
};

export default ContentFoundCheaper;

import { useEffect } from "react";
import useIsFirstRender from "./useIsFirstRender";

type EffectCallback = () => void | (() => void);

const useUpdateEffect = (effect: EffectCallback, deps: React.DependencyList) => {
  const isFirst = useIsFirstRender();

  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);
};


export default useUpdateEffect;
import bannerImage from "../../../../../assets/bestseller/small-banner.png";
import bannerDesctop from "../../../../../assets/bestseller/small-banner-desctop.png";
import { useAppSelector } from "store/reduxHooks";
import styles from "./styles.module.scss";
import SingleImageLoader from "components/UI/SingleImageLoader/SingleImageLoader";

const BestsellersBodyBanner: React.FC = () => {
  const { deviceType } = useAppSelector((state) => state.user);
  const bannerSrc = deviceType.isLargeDesktop ? bannerDesctop : bannerImage;
  return (
    <div className={styles.container}>
      <div className={styles["image-wrapper"]}>
        <SingleImageLoader
          src={bannerSrc}
          alt="Баннер преимуществ покупки в Parfumart"
          className={styles.image}
        />
      </div>
      <div className={styles.text}>
        <h2 className={styles.text__title}>Оригинальные товары в Parfumart</h2>
        <p className={styles.text__description}>
          Приобретая товары в нашем интернет-магазине, вы гарантированно
          получаете только оригинальную продукцию
        </p>
      </div>
    </div>
  );
};

export default BestsellersBodyBanner;

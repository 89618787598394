import { useAppSelector } from "store/reduxHooks";
import { nanoid } from "@reduxjs/toolkit";

import MainSlider from "../../../../components/MainSlider/MainSlider";
import OurNewsItem from "./OurNewsItem/OurNewsItem";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import firstNewImg from "../../../../assets/mock_images/first_mock-img.png";
import secondNewImg from "../../../../assets/mock_images/second_mock-img.png";
import thirdNewImg from "../../../../assets/mock_images/third_mock-img.png";
import { IOurNewsItem } from "types/IOurNewsItem";

import styles from "./styles.module.scss";

const OUR_NEWS_MOCK: IOurNewsItem[] = [
  {
    id: nanoid(),
    imgSrc: firstNewImg,
    date: "25 Мая 2022",
    description: "M.Micallef показали новую версию Note Vanillée — Nectar"
  },
  {
    id: nanoid(),
    imgSrc: secondNewImg,
    date: "2 Декабря 2022",
    description:
      "Montblanc объединили в новом Signature иланг-иланг, туберозу и франжипани"
  },
  {
    id: nanoid(),
    imgSrc: thirdNewImg,
    date: "2 Января 2023",
    description:
      "L'Artisan Parfumeur перевыпустили Passage d’Enfer к Китайскому Новому году "
  }
];

const OurNews: React.FC = () => {
  const { deviceType } = useAppSelector((state) => state.user);

  return (
    <section className={styles.root}>
      <SectionTitle title="Наши новости" />
      <div className={styles["news-wrapper"]}>
        {!deviceType.isMobile ? (
          OUR_NEWS_MOCK.map((item) => {
            return (
              <OurNewsItem
                key={item.id}
                imgSrc={item.imgSrc}
                date={item.date}
                description={item.description}
              />
            );
          })
        ) : (
          <MainSlider shouldBeLooped>
            {OUR_NEWS_MOCK.map((item) => {
              return (
                <OurNewsItem
                  key={item.id}
                  imgSrc={item.imgSrc}
                  date={item.date}
                  description={item.description}
                  containerStyles="keen-slider__slide"
                />
              );
            })}
          </MainSlider>
        )}
      </div>
    </section>
  );
};

export default OurNews;

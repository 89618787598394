import { useMemo, useState } from "react";
import CustomLink from "../../../../../../../components/CustomLink/CustomLink";

import cn from "classnames";
import styles from "./styles.module.scss";
import { AboutProduct } from "./Tabs/AboutProduct/AboutProduct";
import { AboutBrand } from "./Tabs/AboutBrand/AboutBrand";
import { ProductComposition } from "./Tabs/ProductComposition/ProductComposition";
import { ICategory } from "types/ICatalog";
import { IOffer, IProperty } from "types/IOffer";

interface IProductDescriptionProps {
  brandDescription?: string;
  brandTitle?: string;
  brandRewriteName?: string;
  productTitle?: string;
  productRewrateName?: string;
  description?: string;
  category?: ICategory;
  offers?: IOffer[];
  properties?: IProperty[];
  scrollToReviews: () => void;
}

interface ITab {
  id: number;
  title: string;
  Tab: React.FC<IProductDescriptionProps>;
}

const tabs: ITab[] = [
  {
    id: 0,
    title: "О товаре",
    Tab: AboutProduct
  },
  {
    id: 1,
    title: "О бренде",
    Tab: AboutBrand
  },
  {
    id: 2,
    title: "Состав",
    Tab: ProductComposition
  },
];

const renderingTabCondition = (tab: { id: number }, props: IProductDescriptionProps) => {
  if (tab.id === 1) return process.env.REACT_APP_COMPONENT_ENABLE_ABOUT_OFFER_BRAND === "false" || !props.brandDescription;
  if (tab.id === 2) return true;
};

const ProductDescriptionInfo: React.FC<IProductDescriptionProps> = (props) => {

  const tabsFiltered = useMemo(() => {
    return tabs.filter((tab) => !renderingTabCondition(tab, props));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentDescriptionButtonId, setCurrentDescriptionButtonId] = useState(tabsFiltered?.[0].id);

  const serialProduct = useMemo(() => {
    return props?.properties?.find((item) => {
      return (
        item.property_value?.property?.rewrite_name?.toLowerCase() === "seria"
      );
    });
  }, [props.properties]);

  const onClickToggleProductDescription = (id: number) => {
    setCurrentDescriptionButtonId(id);
  };

  return (
    <div className={styles.root}>

      <div className={styles["description-header"]}>
        {tabsFiltered.map((item) => {
          return (
            <button
              key={item.id}
              onClick={() => onClickToggleProductDescription(item.id)}
              className="description-header__button"
            >
              <h4
                className={cn(styles["description-header__title"], {
                  [styles["description-header__title--active"]]:
                    currentDescriptionButtonId === item.id,
                })}
              >
                {item.title}
              </h4>
            </button>
          );
        })}
      </div>

      {tabsFiltered.map(({ Tab, id }) => {
        if (id === currentDescriptionButtonId) return <Tab key={id} {...props} />;
      })}

      <div className={styles["buttons-wrapper"]}>
        <CustomLink
          path={`/catalog?brands=${props.brandRewriteName}`}
          title={`Все товары ${props.brandTitle}`}
          isBlack
        />
        {serialProduct && (
          <CustomLink
          // если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ] пример properties%5Bkategoria%5D
            path={`/catalog?properties%5B${serialProduct.property_value.property.rewrite_name}%5D=${serialProduct.property_value.rewrite_name}`}
            title={`Все товары серии ${serialProduct.property_value.name}`}
            isWhite
          />
        )}
        {props.category && (
          <CustomLink
            path={`/catalog/${props.category.rewrite_name}`}
            title={`Вся ${props.category.name.toLowerCase()}`}
            isWhite
          />
        )}
      </div>

    </div>
  );
};

export default ProductDescriptionInfo;

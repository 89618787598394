import { IFormattedCartItem } from "types/IFormattedCartItem";

export const sortCartByAvailable = (
  a: IFormattedCartItem,
  b: IFormattedCartItem
) => {
  if (a.offer.available && !b.offer.available) {
    return -1;
  }
  if (!a.offer.available && b.offer.available) {
    return 1;
  }
  return 0;
};

export const getProductTitleInGenitiveCase = (amount: number) => {
  const word = "Товар";
  let ending;

  const lastDigit = amount % 10;
  const secondToLastDigit = Math.floor((amount % 100) / 10);

  if (secondToLastDigit === 1) {
    ending = "ов";
  } else {
    if (lastDigit === 1) {
      ending = "";
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      ending = "а";
    } else {
      ending = "ов";
    }
  }

  return `${word}${ending}`;
};

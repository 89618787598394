/* eslint-disable @typescript-eslint/no-explicit-any */
import { YANDEX_METRICS_ID } from "./yandexMetricsCore";
// target - идентификатор цели (задается на странице редактирования счетчика при создании или изменении цели);
// params – можно передавать собственные параметры визита;
// callback - функция, вызываемая после отправки данных о просмотре;
// ctx - контекст, доступный в callback-функции по ключевому слову this.

declare const ym: (
  id: string | number,
  method: string,
  target: string,
  params?: CallYmParams | any,
  callback?: Callback | null,
  ctx?: any
) => void;

export interface CallYmParams {
  order_price: number;
  currency: string;
}

export type Callback = () => void;

const callYm = (
  method: string,
  target: string,
  params: CallYmParams = { order_price: 0, currency: "RUB" },
  callback: Callback | null = null,
  ctx: any = null
): void => {
  if (typeof ym !== "undefined") {
    // eslint-disable-next-line no-undef
    ym(YANDEX_METRICS_ID, method, target, params, callback, ctx);
  } else {
    // eslint-disable-next-line no-console
    console.error("Yandex Metrika не загрузилась");
  }
};

const addToBasketMetric = (
  orderPrice: number,
  currency = "RUB",
  callback: Callback | null = null,
  ctx: any = null
): void => {
  const params: CallYmParams = { order_price: orderPrice, currency: currency };
  callYm("reachGoal", "addToBasket", params, callback, ctx);
};

const newOrderCreateMetric = (
  orderPrice: number,
  currency = "RUB",
  callback: Callback | null = null,
  ctx: any = null
): void => {
  const params: CallYmParams = { order_price: orderPrice, currency: currency };
  callYm("reachGoal", "newOrderCreate", params, callback, ctx);
};

export const metric = {
  addToBasketMetric,
  newOrderCreateMetric
};

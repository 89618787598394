import ProductDescriptionInfo from "./ProductDescriptionInfo/ProductDescriptionInfo";

import styles from "./styles.module.scss";
import { IOffer, IProperty } from "types/IOffer";
import { ICategory } from "types/ICatalog";

interface IProductDescriptionProps {
  brandDescription?: string;
  brandTitle?: string;
  brandRewriteName?: string;
  productTitle?: string;
  productRewrateName?: string;
  description?: string;
  category?: ICategory;
  offers?: IOffer[];
  properties?: IProperty[];
  scrollToReviews: () => void;
}

const ProductDescription: React.FC<IProductDescriptionProps> = (props) => {
  return (
    <div className={styles.root}>
      <ProductDescriptionInfo
        {...props}
      />
    </div>
  );
};

export default ProductDescription;

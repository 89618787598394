import { useRef } from "react";

import ExitFromPersonalAreaContent from "../../../components/ExitFromPersonalAreaContent/ExitFromPersonalAreaContent";

import styles from "./styles.module.scss";

interface ExitModalProps {
  onClose: (data: { isExit: boolean }) => void;
}

const ExitModal: React.FC<ExitModalProps> = (props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClose = (data: { isExit: boolean }) => {
    props.onClose(data);
  };

  return (
    <ExitFromPersonalAreaContent
      containerStyles={styles.root}
      onClose={handleClose}
      contentRef={modalRef}
    />
  );
};

export default ExitModal;

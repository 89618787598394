import { ICategory } from "types/ICatalog";
import { IProperty } from "types/IOffer";

interface ProductCompositionProps {
  brandTitle?: string;
  brandRewriteName?: string;
  description?: string;
  productRewrateName?: string;
  category?: ICategory;
  properties?: IProperty[];
}

export const ProductComposition: React.FC<ProductCompositionProps> = () => {
  return (
    <>
      <div></div>
    </>
  );
};
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { getSiteTitle } from "./index";
import logo from "../assets/parfumart_logo.png";
import { getCanonicalUrl } from "./helpers/getCanonicalUrl";
import { useLocation } from "react-router-dom";

interface HeadPageDataProps {
  title?: string;
  description?: string;
  canonical?: string;
}

const defaultProps: Partial<HeadPageDataProps> = {
  description:
    "Интернет-магазин парфюмерии и косметики. Большой выбор оригинальной парфюмерии и профессиональной косметики с доставкой по РФ. Низкие цены на парфюмы и косметику для женщин и мужчин, постоянные скидки и акции",
  canonical: "https://parfumart.ru"
};

const HeadPageData: React.FC<HeadPageDataProps> = (props) => {
  const {
    title,
    description = defaultProps.description,
    canonical: propCanonical = defaultProps.canonical
  } = props;
  const location = useLocation();

  const isProductPage = useMemo(() => {
    const currentMatch = location.pathname.match(/product/g);
    if (!currentMatch) {
      return false;
    }
    return true;
  }, [location.pathname]);

  const shouldNoIndex = (url: string): boolean => {
    const noIndexPages = [
      "/catalog/parfumeria?kategoria=dla-volos",
      "/catalog/parfumeria?kategoria=dla-lica",
      "/catalog/parfumeria?kategoria=dla-tela",
      "/catalog/kosmetika?kategoria=selektivnaa",
      "/catalog/parfumeria?kategoria=dla-doma"
    ];
    const urlObj = new URL(url);
    const pathWithQuery = `${urlObj.pathname}${urlObj.search}`;
    return noIndexPages.includes(pathWithQuery);
  };

  let canonicalUrl = isProductPage
    ? `${process.env.REACT_APP_PARFUMART_PUBLIC}${location.pathname}`
    : getCanonicalUrl(window.location.href);
  if (!canonicalUrl) {
    canonicalUrl = propCanonical;
  }
  const ogUrl = isProductPage
    ? `${process.env.REACT_APP_PARFUMART_PUBLIC}${location.pathname}`
    : getCanonicalUrl(window.location.href);
  const noIndex = shouldNoIndex(window.location.href);

  return (
    <Helmet>
      <title>{getSiteTitle(title)}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:title" content={getSiteTitle(title)} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:image" content={logo} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={getSiteTitle(title)} />
      <meta name="twitter:description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default HeadPageData;

import Portal from "../../../HOC/Portal";
import BrandsByLetterContent from "../BrandsByLetterContent/BrandsByLetterContent";

import { ReactComponent as CrossIcon } from "../../../assets/icons/cross-icon.svg";

import styles from "./styles.module.scss";

interface BrandsByLetterModalProps {
  modalRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}

const BrandsByLetterModal: React.FC<BrandsByLetterModalProps> = (props) => {
  return (
    <Portal modalRef={props.modalRef} onClose={props.onClose}>
      <section ref={props.modalRef} className={styles.root}>
        <button className={styles["cross-icon"]} onClick={props.onClose}>
          <CrossIcon />
        </button>
        <BrandsByLetterContent {...props} />
      </section>
      <div className={styles.background} />
    </Portal>
  );
};

export default BrandsByLetterModal;

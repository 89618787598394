import { productsApi } from "api/productsApi";
import { useCallback, useEffect, useRef, useState } from "react";
import { getQueryStringForRequest } from "utils/helpers/catalogPageHelper";
import { CustomError, getErrorData, REJECT_ERROR_CODE } from "utils/getErrorData";
import { IProductWithOffers } from "types/IProduct";
import { isError } from "store/user/userThunks/userThunks";

const MAX_PRODUCTS_PER_PAGE = 18;

const useBestsellersProducts = () => {
  const [products, setProducts] = useState<IProductWithOffers[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [totalProducts, setTotalProducts] = useState<number | null>(null);

  const abortController = useRef<AbortController | null>(null);

  useEffect(() => {
    if (!shouldFetch) {
      return;
    }
    if (totalProducts !== null && products.length >= totalProducts) {
      setShouldFetch(false);
      return;
    }
    (async () => {
      try {
        abortController.current = new AbortController();
        setIsLoadingProducts(true);

        const requestString = getQueryStringForRequest({
          limit: String(MAX_PRODUCTS_PER_PAGE),
          offset: String(products.length),
          sort_by: "updated_at",
          order_by: "asc",
          categories: "parfumeria",
          badges: "bestseller"
        });

        const data = await productsApi.getCatalogWithSearchOptions({
          requestString,
          signal: abortController.current.signal
        });

        if (isError(data)) {
          const errorData = getErrorData(data);
          throw new CustomError(errorData.message, errorData?.code);
        }

        setProducts((prevProducts) => [...prevProducts, ...data.response.items]);
        setTotalProducts(data.response.meta.total);
        setShouldFetch(false);
      } catch (err) {
        if (err instanceof Error && err.name !== "AbortError") {
          throw new CustomError("При попытке показать товары, произошла ошибка", REJECT_ERROR_CODE);
        }
        const errorData = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error(errorData.message);
      } finally {
        setIsLoadingProducts(false);
      }
    })();

    return () => {
      abortController.current?.abort();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTrigger]);

  const handleChangeShouldGetProductsStatus = useCallback((value: boolean) => {
    setShouldFetch(value);
    setFetchTrigger((prev) => prev + 1);
  }, []);

  return {
    products,
    isLoadingProducts,
    handleChangeShouldGetProductsStatus
  };
};

export default useBestsellersProducts;
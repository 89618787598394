import { IProductResponse } from "types/IGetProductOffersThunk";
import { IPublicProductsResponse } from "types/IPublicProducts";
import {
  CustomError,
  getErrorData,
  IErrorData,
  IGenericResponseRoot
} from "utils/getErrorData";

const getProductsWithLimit = async (
  options: Record<string, string | undefined>
): Promise<IGenericResponseRoot<IPublicProductsResponse> | IErrorData> => {
  try {
    const requestData = Object.keys(options)?.map((item, idx) => {
      if (typeof options[item] === "undefined") {
        return "";
      }
      if (idx === 0) {
        return `?${item}=${options[item]}`;
      }
      return `&${item}=${options[item]}`;
    });

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/products${requestData.join("")}`,
      {
        method: "GET"
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        "Произошла необработанная ошибка при получении товаров",
        500
      );
    }

    const data: IGenericResponseRoot<IPublicProductsResponse> =
      await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return data;
  } catch (error) {
    return getErrorData(error);
  }
};

const getProductById = async (
  id: number
): Promise<IGenericResponseRoot<IProductResponse> | IErrorData> => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/public/product/v2/${id}`,
      {
        method: "GET"
      }
    );

    if (res.status === 500) {
      throw new CustomError(
        `Произошла необработанная ошибка при получении товара по ID: ${id}`,
        500
      );
    }

    const data: IGenericResponseRoot<IProductResponse> = await res.json();

    if (!res.ok) {
      const errorData = getErrorData(data);
      throw new CustomError(errorData.message, errorData?.code);
    }

    return data;
  } catch (error) {
    return getErrorData(error);
  }
};

// TODO - описать типы и обработать ошибки у getDataForProfitSection
const getDataForProfitSection = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/public/sale-blocks`,
    {
      method: "GET"
    }
  );

  return res.json();
};

// TODO - добавить тип на промис
const getCatalogWithSearchOptions = ({
  requestString,
  signal
}: {
  requestString: string;
  signal: AbortSignal;
}): Promise<IGenericResponseRoot<IPublicProductsResponse> | IErrorData> => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/public/products${requestString}`,
          {
            method: "GET",
            signal
          }
        );

        if (res.status === 500) {
          throw new CustomError(
            "Произошла необработанная ошибка при получении товаров",
            500
          );
        }

        const data: IGenericResponseRoot<IPublicProductsResponse> =
        await res.json();

        if (!res.ok) {
          const errorData = getErrorData(data);
          throw new CustomError(errorData.message, errorData?.code);
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    }, 1000);
  });
};

export const productsApi = {
  getProductsWithLimit,
  getProductById,
  getDataForProfitSection,
  getCatalogWithSearchOptions
};

import { useAppDispatch } from "store/reduxHooks";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { brandsApi } from "../../../api/brands";
import useShowShadow from "../../../utils/hooks/useShowShadow";

import CurrentBrandLetterBlock from "./components/CurrentBrandLetterBlock/CurrentBrandLetterBlock";
import LettersRow from "./components/LettersRow/LettersRow";
import { IBrandWrapped } from "types/ICatalog";

import cn from "classnames";
import styles from "./styles.module.scss";
import {
  ARR_NUMBERS,
  CYRILLIC_ALPHABET
} from "../../../utils/constants/arrAlphabets";
import { toggleSearchTrigger } from "../../../store/catalogV3/index";
import { getErrorData } from "utils/getErrorData";

interface IBrandsByLetterContentProps {
  onClose: () => void;
}

export type FilterType = "all" | "kosmetika" | "parfumeria";

export interface IBrandsState {
  all: IBrandWrapped[];
  kosmetika: IBrandWrapped[];
  parfumeria: IBrandWrapped[];
}

const BrandsByLetterContent: React.FC<IBrandsByLetterContentProps> = (props) => {
  const [brands, setBrands] = useState<IBrandsState>({ all: [], kosmetika: [], parfumeria: [] });
  const [selectedLetter, setSelectedLetter] = useState("");
  const [activeFilter, setActiveFilter] = useState<FilterType>("all");
  const { showShadow, changeShowShadow, onScrollHandler } = useShowShadow();
  const collator = new Intl.Collator("ru");

  const dispatch = useAppDispatch();
  const brandsRef = useRef<HTMLDivElement | null>(null);

  const getBrands = (response: { [key: string]: IBrandWrapped[] }): IBrandWrapped[] => {
    const items: IBrandWrapped[] = [];

    if (typeof response === "object") {
      for (const key in response) {
        items.push(...response[key]);
      }
    }

    return items;
  };

  const brandsSort = (brands: IBrandWrapped[], letter: string): IBrandWrapped[] => {
    const brandsFilter = brands.reduce((previous: IBrandWrapped[], current: IBrandWrapped) => {
      if (
        !previous.filter(
          (item) => item.brand["rewrite_name"] === current.brand["rewrite_name"]
        )[0]
      ) {
        previous.push(current);
      }
      return previous;
    }, []);

    if (letter === "А-Я")
      return brandsFilter.sort((a, b) =>
        collator.compare(a.brand.name, b.brand.name)
      );
    if (letter === "0-9")
      return brandsFilter.sort((a, b) =>
        a.brand.name > b.brand.name ? 1 : -1
      );

    return brandsFilter.sort((a, b) => {
      const aBrand = a.brand.name.toLowerCase().replace(/[^a-zA-Z]/gi, "");
      const bBrand = b.brand.name.toLowerCase().replace(/[^a-zA-Z]/gi, "");

      if (aBrand > bBrand) return 1;
      if (aBrand < bBrand) return -1;
      return 0;
    });
  };

  const brandsByLetters = async (letters: string[], letter: string) => {
    try {
      const res = await brandsApi.getBrandsByLetters(letters);

      if ("message" in res) {
        throw new Error(res.message);
      }

      if (!res.response) return;
      const kosmetika = getBrands(res.response.kosmetika);
      const parfumeria = getBrands(res.response.parfumeria);
      const allBrands = brandsSort([...kosmetika, ...parfumeria], letter);

      setBrands({
        all: allBrands,
        kosmetika,
        parfumeria
      });
    } catch (err) {
      const error = getErrorData(err);
      // eslint-disable-next-line no-console
      console.error("Произошла ошибка получение брендов по буквам", error.message);
    }
  };

  useEffect(() => {
    onSelectLetter("A");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!brandsRef.current) {
      return;
    }

    changeShowShadow({
      top: false,
      bottom: brandsRef.current.scrollHeight > brandsRef.current.clientHeight
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands]);

  const onSelectLetter = async (letter: string) => {
    if (letter === selectedLetter) return;

    setSelectedLetter(letter);

    if (letter === "А-Я") return brandsByLetters(CYRILLIC_ALPHABET, letter);
    if (letter === "0-9") return brandsByLetters(ARR_NUMBERS, letter);

    brandsByLetters([letter], letter);
  };

  const onSelectFilter = (filter: FilterType) => {
    if (filter === activeFilter) {
      return;
    }
    setActiveFilter(filter);
  };

  const handlerClickLink = () => {
    dispatch(toggleSearchTrigger());

    props.onClose();
  };

  return (
    <>
      <CurrentBrandLetterBlock
        selectedLetter={selectedLetter}
        activeFilter={activeFilter}
        brands={brands}
        onSelectFilter={onSelectFilter}
      />
      <div className={styles["content-wrapper"]}>
        <LettersRow
          selectedLetter={selectedLetter}
          onSelectLetter={onSelectLetter}
        />
        <div
          className={cn(
            styles["brands-container"],
            { [styles["brands-container--top-shadow"]]: showShadow.top },
            { [styles["brands-container--bottom-shadow"]]: showShadow.bottom }
          )}
        >
          <div
            className={styles.brands}
            onScroll={onScrollHandler}
            ref={brandsRef}
          >
            {brands[activeFilter]?.map((item, idx) => {
              const link =
                activeFilter === "all"
                  ? `/catalog?brands=${item.brand.rewrite_name}`
                  : `/catalog/${activeFilter}/?brands=${item.brand.rewrite_name}`;

              return (
                <NavLink
                  key={`${item.brand.rewrite_name}_${idx}`}
                  to={link}
                  onClick={handlerClickLink}
                  className={styles.brands__item}
                >
                  {item.brand.name}
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandsByLetterContent;

import React, { ReactNode } from "react";
import Bestsellers from "pages/LandingPages/Bestsellers/Bestsellers";

interface LandingPageConfig {
  pageName: string;
  content: () => ReactNode;
}

const landingPagesConfig: LandingPageConfig[] = [
  {
    pageName: "bestsellers",
    content: () => React.createElement(Bestsellers)
  }
];

export default landingPagesConfig;
import { productsApi } from "api/productsApi";
import { useEffect, useState } from "react";
import { isError } from "store/user/userThunks/userThunks";
import { IProductResponse } from "types/IGetProductOffersThunk";
import { CustomError, getErrorData, IGenericResponseRoot } from "utils/getErrorData";

const useProductsByIds = (ids: number[]) => {
  const [products, setProducts] = useState<IProductResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const productPromises = ids.map(async (id) => {
          const response = await productsApi.getProductById(id);
          if (isError(response)) {
            const errorData = getErrorData(response);
            throw new CustomError(errorData.message, errorData?.code);
          }
          return response;
        });
        const results = await Promise.allSettled(productPromises);

        const successfulResults = results.reduce<IGenericResponseRoot<IProductResponse>[]>((acc, result) => {
          if (result.status === "fulfilled") {
            acc.push(result.value);
          }
          return acc;
        }, []);

        setProducts(successfulResults.map(result => result.response));

      } catch (err) {
        const errorMessage = getErrorData(err);
        // eslint-disable-next-line no-console
        console.error(errorMessage.message);
        setError(errorMessage.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [ids]);

  return { products, isLoading, error };
};

export default useProductsByIds;
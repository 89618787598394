import {
  AnyAction,
  CombinedState,
  createAsyncThunk,
  ThunkDispatch
} from "@reduxjs/toolkit";
import { userApi } from "../../../api/userApi";
import { getUserToken, setTokens } from "../../../utils/localStorage";
import { CustomError, getErrorData, IErrorData } from "utils/getErrorData";
import { IResponseAddToCartPublic } from "types/IAddProductToCartPublic";
import { IGetUserCartRequest, IGetUserCartResponse } from "types/IGetUserCart";
import { Offer } from "types/UserApiTypes";
import { isError } from "store/user/userThunks/userThunks";
import { IRequestAddToCartAuthorized } from "types/IAddProductToCart";
import { RootState } from "store/store";
import { toast } from "react-toastify";

export interface IAddPublicCartData {
  addProduct: boolean;
  offers: Offer[];
}

export const isAddPublicDataType = (
  data: unknown
): data is IAddPublicCartData => {
  return typeof data === "object" && "addProduct" in data && "offers" in data;
};

export const isGetPrivateDataType = (
  data: unknown
): data is IGetUserCartRequest => {
  return (
    (typeof data === "object" &&
      ("use_promo_code" in data ||
        "use_bonus" in data ||
        "calculate_orders" in data)) ||
    typeof data === "object"
  );
};

export interface IAddProductToCartPublicProps {
  options: Offer[];
  getState: () => CombinedState<RootState>;
  dispatch: ThunkDispatch<CombinedState<RootState>, unknown, AnyAction>;
}

export const getUserCart = createAsyncThunk<
  IResponseAddToCartPublic | IGetUserCartResponse,
  IAddPublicCartData | IGetUserCartRequest,
  { rejectValue: IErrorData }
>("cart/getUserCart", async (options, { rejectWithValue }) => {
  const accessToken = getUserToken();
  if (!accessToken) {
    const storageValue = localStorage.getItem("cart") || "[]";
    const optionsCart =
      isAddPublicDataType(options) && options.addProduct
        ? options.offers
        : [...JSON.parse(storageValue)];
    const filteredValue: Offer[] = optionsCart.filter((e) => e.count > 0);

    if (!filteredValue[0]) {
      localStorage.setItem("cart", "[]");
    }

    try {
      const data = await userApi.addProductToCartPublic(filteredValue);

      if (isError(data)) {
        throw new CustomError(data.message, data?.code);
      }

      const setLocalStorage = JSON.stringify(filteredValue);
      localStorage.setItem("cart", setLocalStorage);
      return data.response;
    } catch (err) {
      const errorData = getErrorData(err);
      return rejectWithValue(errorData);
    }
  }

  try {
    const privateCartOptions = isGetPrivateDataType(options) && options;
    const data = await userApi.getUserCart(privateCartOptions);

    if (isError(data)) {
      throw new CustomError(data.message, data?.code);
    }

    return data.response;
  } catch (err) {
    const errorData = getErrorData(err);
    // TODO: Когда добавиться пендинг и реджект убрать тост, добавить его в реджектед
    toast.error(errorData.message);
    return rejectWithValue(errorData);
  }
});

export const addProductToCart = createAsyncThunk<
  void,
  Offer[],
  { rejectValue: IErrorData; state: RootState }
>(
  "cart/addProductToCart",
  async (options, { rejectWithValue, getState, dispatch }) => {
    const accessToken = getUserToken();

    if (!accessToken) {
      return addProductToCartPublic({ options, getState, dispatch });
    }

    try {
      const requestData: IRequestAddToCartAuthorized = {
        offers: options
        // все остальные поля что требуются в IRequestAddToCartAuthorized
      };
      const data = await userApi.addProductToCart(requestData);

      if (isError(data)) {
        throw new CustomError(data.message, data?.code);
      }

      const cart = getState().user.cart;

      const selectedProductsInCart = cart.reduce((acc, cur) => {
        if (cur.isSelected) {
          acc.push(cur.offer.id);
        }
        return acc;
      }, [] as number[]);

      const cartResponseProductsIds = data.response.items.reduce((acc, cur) => {
        acc.push(cur.offer.id);
        return acc;
      }, [] as number[]);

      const selectedProductsFromResponse = cartResponseProductsIds.filter(
        (num) => selectedProductsInCart.includes(num)
      );

      if (selectedProductsFromResponse.length) {
        const optionsCalculate = {
          calculate_orders: selectedProductsFromResponse.join(","),
          use_promo_code: getState().user.checkoutData.promoCode.trim()
        };

        dispatch(getUserCart(optionsCalculate));
      } else {
        dispatch(getUserCart({}));
      }
    } catch (err) {
      const errorData = getErrorData(err);

      if (errorData?.code === 401) {
        try {
          const refreshData = await userApi.refreshUser();

          if (isError(refreshData)) {
            throw new CustomError(refreshData.message, refreshData?.code);
          }

          setTokens({
            token: refreshData.response.token,
            refreshToken: refreshData.response.refresh
          });

          dispatch(addProductToCart(options));
        } catch (error) {
          return rejectWithValue({ message: getErrorData(error).message });
        }
      }
      return rejectWithValue({ message: errorData.message });
    }
  }
);

const addProductToCartPublic = async (data: IAddProductToCartPublicProps) => {
  const { options, getState, dispatch } = data;
  let state = getState().user.cart.map((e) => {
    return { id: e.offer.id, count: e.count };
  });

  options.map((e) => {
    const isFind = state.find((el) => el.id === e.id);
    if (isFind) {
      return (state = state.map((el) => {
        if (isFind.id === el.id) return e;
        return el;
      }));
    }
    return state.push(e);
  });

  const optionData = { addProduct: true, offers: state };
  dispatch(getUserCart(optionData));
};

import { IFooteLinkItem } from "../../types/IFooterLinkItem";

// если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
export const PARFUME_LINKS: IFooteLinkItem[] = [
  {
    title: "Селективная",
    link: "/catalog/parfumeria?properties%5Bkategoria%5D=selektivnaa"
  },
  {
    title: "Элитная",
    link: "/catalog/parfumeria?properties%5Bkategoria%5D=elitnaa"
  }
];
// если фильтр в Каталоге относится к product_properties - мы переименовываем его ключ в properties[ключ]
export const COSMETIC_LINKS: IFooteLinkItem[] = [
  {
    title: "Для Волос",
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-volos"
  },
  {
    title: "Для Тела",
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-tela"
  },
  {
    title: "Для Макияжа",
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-makiaza"
  },
  {
    title: "Для Полости Рта",
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-polosti-rta"
  },
  {
    title: "Для Солнца и Загара",
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-solnca-i-zagara"
  },
  {
    title: "Для Детей",
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-detej"
  },
  {
    title: "Для Ногтей",
    link: "/catalog/kosmetika?properties%5Bkategoria%5D=dla-nogtej"
  },
  {
    title: "Косметика для Мужчин",
    link: "/catalog/kosmetika?properties%5Bklassifikacia%5D=muzskaa-kosmetika"
  }
];

export const COMPANY_LINKS: IFooteLinkItem[] = [
  {
    title: "Подарочные сертификаты",
    link: "/info/podarocnye-sertifikaty"
  },
  {
    title: "Система скидок",
    link: "/info/sistema-skidok"
  },
  {
    title: "О нас",
    link: "/info/o-nas"
  }
];

export const INFORMATION_LINKS: IFooteLinkItem[] = [
  {
    title: "Контакты",
    link: "/info/kontakty"
  },
  {
    title: "Оплата",
    link: "/info/oplata"
  },
  {
    title: "Доставка",
    link: "/info/dostavka"
  },
  {
    title: "График работы",
    link: "/info/grafik-raboty"
  },
  {
    title: "Возврат и обмен",
    link: "/info/vozvrat-i-obmen"
  },
  {
    title: "Для юридических лиц",
    link: "/info/dlya-yuridiceskix-lic"
  },
  {
    title: "Как заказать",
    link: "/info/kak-zakazat"
  },
  {
    title: "Публичная оферта",
    link: "/info/publichnaya-oferta"
  }
];

import { CustomExpandingText } from "../../../../../../../../../components/CustomExpandingText/CustomExpandingText";
import styles from "./styles.module.scss";

interface AboutBrandProps {
  brandDescription?: string;
}

export const AboutBrand: React.FC<AboutBrandProps> = (props) => {
  return (
    <>
      {props.brandDescription ?
        <div className={styles.description}>
          <CustomExpandingText text={props.brandDescription} />
        </div>
        :
        <div className={styles["description-empty"]}>
          <h2>Нет описания</h2>
        </div>
      }
    </>
  );
};
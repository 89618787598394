import cn from "classnames";
import styles from "./styles.module.scss";

interface ShoppingAdvantageItemProps {
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
  containerStyles?: string;
}

const ShoppingAdvantageItem: React.FC<ShoppingAdvantageItemProps> = (props) => {
  return (
    <li className={cn(styles.root, props.containerStyles)}>
      <div className={styles["logo-wrapper"]}>
        <img src={props.imgSrc} alt={props.imgAlt} className={styles.logo} />
      </div>
      <h3 className={styles.title}>{props.title}</h3>
      <p className={styles.description}>{props.description}</p>
    </li>
  );
};

export default ShoppingAdvantageItem;

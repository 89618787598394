import { useCallback, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import BestsellersProductCard from "../BestsellersProductCard/BestsellersProductCard";
import SkeletonBestsellerCard from "components/UI/Skeleton/SkeletonBestsellerCard/SkeletonBestsellerCard";
import { useAppSelector } from "store/reduxHooks";
import useBestsellersProducts from "utils/hooks/useBestsellersProducts";


const BestsellersLikeItBlock: React.FC = () => {
  const { products, isLoadingProducts, handleChangeShouldGetProductsStatus } = useBestsellersProducts();
  const { deviceType } = useAppSelector((state) => state.user);
  const observer = useRef<IntersectionObserver | null>(null);

  const lastProductElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoadingProducts) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleChangeShouldGetProductsStatus(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingProducts, handleChangeShouldGetProductsStatus]
  );

  useEffect(() => {
    handleChangeShouldGetProductsStatus(true);
  }, [handleChangeShouldGetProductsStatus]);

  const getSkeletonCount = () => {
    if (deviceType.isLargeDesktop) return 12;
    if (deviceType.isDesktop) return 8;
    if (deviceType.isTablet) return 6;
    return 3;
  };

  const filteredProducts = products.filter(product => product.offers.length > 0);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Вам может понравиться</h2>
      <div className={styles.products}>
        {filteredProducts.length === 0 && isLoadingProducts &&
          Array.from({ length: getSkeletonCount() }).map((_, index) => <SkeletonBestsellerCard key={index} />)}
        {filteredProducts.map((product, index) => (
          <BestsellersProductCard
            key={product.id}
            product={product}
            cardRef={filteredProducts.length === index + 1 ? lastProductElementRef : undefined}
          />
        ))}
        {filteredProducts.length > 0 && isLoadingProducts &&
          Array.from({ length: getSkeletonCount() }).map((_, index) => <SkeletonBestsellerCard key={index} />)}
      </div>
    </div>
  );
};

export default BestsellersLikeItBlock;

import { combineReducers } from "@reduxjs/toolkit";
import user from "./user/user";
import catalogV3 from "./catalogV3";
import banners from "./banners/banners";

const rootReducer = combineReducers({
  user,
  catalogV3,
  banners
});

export default rootReducer;

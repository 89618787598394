import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  addDeliveryAdress,
  addUserFavoriteProduct,
  getProductOffersByIds,
  getUserFavoriteProducts,
  removeDeliveryAdress,
  removeUserFavoriteProduct,
  signInUser,
  signUpUser,
  updateUserData
} from "./userThunks/userThunks";
import { getUserCart } from "./cartThunks/cartThunks";
import { sortCartByAvailable } from "../../utils/cartUtils";
import { WAY_TO_GET } from "../../utils/constants";
import { setUserCityStorage } from "../../utils/localStorage";
import { UserState } from "types/IUserStore";
import { IOffer } from "types/IOffer";
import { IProviderInfoStore } from "types/IProviderInfoStore";
import { ICartItem } from "types/ICartItem";
import { IProductItem } from "types/IUser";
import { IPaymentMethod } from "types/IPaymentMethod";
import { getAvailablePaymentMethods } from "store/user/paymentTypesThunk/paymentTypesThunk";
import { IFormattedCartItem } from "types/IFormattedCartItem";

export interface ProductOffersPayload {
  id: number;
  offers: IOffer[];
}

export const isCartItem = (item: unknown): item is ICartItem => {
  return (
    typeof item === "object" && ("added_at" in item || "updated_at" in item)
  );
};

const initialState: UserState = {
  user: {
    id: null,
    phone: "",
    roles: [],
    email: "",
    created_at: "",
    name: "",
    surname: "",
    patronymic: "",
    profiles: [],
    email_confirmed: false,
    phone_confirmed: false,
    updated_at: null,
    bonus: null,
    favouriteProducts: [],
    accountDeliveryAddresses: [],
    gender: "",
    birthday: "",
    service: ""
  },
  cart: [],
  checkoutData: {
    items: [],
    recipient: {
      name: "",
      surname: "",
      phone: "",
      email: "",
      address: "",
      // eslint-disable-next-line camelcase
      pvz_id: null,
      accountDeliveryAddresses: []
    },
    wayToGet: WAY_TO_GET.toPoint,
    paymentMethod: null,
    promoCode: "",
    orderCity: "",
    coordinates: undefined,
    providerInfo: null
  },
  paymentTypes: {
    types: [],
    isPaymentTypesLoading: true,
    errorMessage: null
  },
  currentCity: "",
  deviceType: {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isLargeDesktop: false
  }
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = {
        ...action.payload,
        favouriteProducts: Object.values(
          action.payload.favouriteProducts
        ).flat()
        // favouriteProducts: Object.values(action.payload.favouriteProducts).map((item) => {
        //   return Object.values(item);
        // }).flat(),
      };
    },
    removeUser(state) {
      state.user = initialState.user;
      state.cart = initialState.cart;
      state.checkoutData = initialState.checkoutData;
      state.currentCity = initialState.currentCity;
    },
    setUserCurrentCity(state, action) {
      state.currentCity = action.payload;
      state.checkoutData.orderCity = action.payload;
      setUserCityStorage(action.payload);
    },
    selectItemInCart(state, action) {
      state.cart = state.cart.map((item) => {
        if (item.offer.id !== action.payload) {
          return item;
        }
        return {
          ...item,
          isSelected: !item.isSelected
        };
      });
    },
    selectItemsInCartByIds(state, action) {
      state.cart = state.cart.map((item) => {
        if (!action.payload.includes(item.offer.id)) {
          return {
            ...item,
            isSelected: false
          };
        }
        return {
          ...item,
          isSelected: true
        };
      });
    },
    selectAllItemsInCart(state) {
      state.cart = state.cart.map((item) => {
        return {
          ...item,
          isSelected: true
        };
      });
    },
    removeAllItemsInCart(state) {
      state.cart = state.cart.map((item) => {
        return {
          ...item,
          isSelected: false
        };
      });
    },
    setCheckoutItems(state: UserState, action: { payload: number[] }) {
      state.checkoutData.items = state.cart.filter((item) =>
        action.payload.includes(item.offer.id)
      );
    },
    setPaymentMethod(state, action: PayloadAction<IPaymentMethod>) {
      state.checkoutData.paymentMethod = action.payload;
    },
    setPromocode(state, action) {
      state.checkoutData.promoCode = action.payload;
    },
    setWayToGet(state, action) {
      state.checkoutData.wayToGet = action.payload;
    },
    setOrderCity(state, action) {
      state.checkoutData.orderCity = action.payload;
    },
    setUserDeliveryAddress(state, action) {
      state.checkoutData.recipient.address = action.payload;
    },
    setDeliveryCoordinates(state, action: PayloadAction<[number, number]>) {
      state.checkoutData.coordinates = action.payload;
    },
    setOrderCityWithCoordinates(state, action) {
      state.checkoutData.orderCity = action.payload.city;
      state.checkoutData.coordinates = action.payload.coordinates;
    },
    setProviderInfo(state, action: PayloadAction<IProviderInfoStore>) {
      state.checkoutData.providerInfo = action.payload;
    },
    setDeviceType(state, action) {
      state.deviceType = action.payload;
    },
    removeCart(state) {
      state.cart = initialState.cart;
    },
    setRecipient(state, action) {
      state.checkoutData.recipient = {
        ...state.checkoutData.recipient,
        ...action.payload
      };
    },
    updateFavouriteProducts(state, action) {
      state.user.favouriteProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUpUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.checkoutData.recipient.address = "";
    });
    builder.addCase(signInUser.fulfilled, (state, action) => {
      const convertedFavouriteProducts =
        action.payload.user.favouriteProducts.map((item: IProductItem) => {
          return item;
        });
      state.user = {
        ...action.payload.user,
        favouriteProducts: convertedFavouriteProducts
      };
      state.checkoutData.recipient.address = "";
    });
    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.user = action.payload.response;
    });
    builder.addCase(addDeliveryAdress.fulfilled, (state, action) => {
      state.user.accountDeliveryAddresses = action.payload;
    });
    builder.addCase(removeDeliveryAdress.fulfilled, (state, action) => {
      state.user.accountDeliveryAddresses = action.payload.response;
    });
    builder.addCase(getUserFavoriteProducts.fulfilled, (state, action) => {
      state.user.favouriteProducts = action.payload;
    });
    builder.addCase(addUserFavoriteProduct.fulfilled, (state, action) => {
      state.user.favouriteProducts = action.payload;
    });
    builder.addCase(removeUserFavoriteProduct.fulfilled, (state, action) => {
      state.user.favouriteProducts = action.payload;
    });
    builder.addCase(
      getProductOffersByIds.fulfilled,
      (state, action: PayloadAction<ProductOffersPayload>) => {
        const formatedFavouriteProducts = state.user.favouriteProducts.map(
          (item) => {
            if (item.product.id !== action.payload.id) {
              return item;
            }
            return {
              ...item,
              offers: action.payload.offers
            };
          }
        );
        state.user.favouriteProducts = formatedFavouriteProducts;
      }
    );
    builder.addCase(getUserCart.fulfilled, (state, action) => {
      const payload = action.payload;
      if (!payload.items) {
        state.cart = [];
        return;
      }
      if (!state.cart.length) {
        const formatedCart: IFormattedCartItem[] = payload.items.map((item) => {
          if (isCartItem(item)) {
            return {
              ...item,
              isSelected: item.offer.available
            };
          }

          return {
            ...item,
            added_at: null,
            updated_at: null,
            isSelected: item.offer.available
          };
        });
        state.cart = [...formatedCart].sort(sortCartByAvailable);
        return;
      }

      const formatedCart = payload.items.map((item) => {
        const finded = state.cart.find((elem) => {
          return elem.offer.id === item.offer.id;
        });
        return {
          ...item,
          added_at: finded?.added_at ?? null,
          updated_at: finded?.updated_at ?? null,
          isSelected: finded ? finded.isSelected : true
        };
      });

      state.cart = [...formatedCart].sort(sortCartByAvailable);
    });
    // TODO: Добавить pending и rejected на getUserCart
    builder.addCase(getAvailablePaymentMethods.fulfilled, (state, action) => {
      state.paymentTypes.types = action.payload;
      state.paymentTypes.isPaymentTypesLoading = false;
      state.paymentTypes.errorMessage = null;
      state.checkoutData.paymentMethod = null;
    });
    builder.addCase(getAvailablePaymentMethods.pending, (state) => {
      state.paymentTypes.isPaymentTypesLoading = true;
      state.paymentTypes.errorMessage = null;
    });
    builder.addCase(getAvailablePaymentMethods.rejected, (state, action) => {
      state.paymentTypes.isPaymentTypesLoading = false;
      state.paymentTypes.errorMessage =
        action.payload?.message || action.error?.message;
      state.paymentTypes.types = [];
      state.checkoutData.paymentMethod = null;
    });
  }
});

export const {
  removeCart,
  setUser,
  removeUser,
  setUserCurrentCity,
  selectItemInCart,
  selectItemsInCartByIds,
  selectAllItemsInCart,
  removeAllItemsInCart,
  setCheckoutItems,
  setPaymentMethod,
  setPromocode,
  setWayToGet,
  setOrderCity,
  setUserDeliveryAddress,
  setDeliveryCoordinates,
  setOrderCityWithCoordinates,
  setProviderInfo,
  setDeviceType,
  setRecipient,
  updateFavouriteProducts
} = user.actions;

export default user.reducer;

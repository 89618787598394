export const getRequestString = (
  options: Record<string, string | string[] | number | number[] | undefined>
): string => {
  const requestData = Object.keys(options)?.map((item, idx) => {
    if (typeof options[item] === "undefined") {
      return "";
    }
    if (idx === 0) {
      return `?${item}=${options[item]}`;
    }
    return `&${item}=${options[item]}`;
  });

  return requestData.join("");
};

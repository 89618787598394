import { useMemo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/reduxHooks";

import { addProductToCart } from "../../../../../store/user/cartThunks/cartThunks";
import getSortedOffersByPriceAndVolume from "../../../../../utils/getSortedOffersByPriceAndVolume";

import saleTicketIcon from "../../../../../assets/icons/png/sale_ticket-icon.png";
import Cosmetics from "../../../../../assets/icons/svg/cosmetics.svg";

import ProductOffersHeader from "../../ProductOffersHeader/ProductOffersHeader";
import MobileOffersList from "./components/MobileOffersList/MobileOffersList";
import DolyamiDropDown from "../../ProductMainContent/components/DropDowns/DolyamiDropDown/DolyamiDropDown";
import HelpDropDown from "../../ProductMainContent/components/DropDowns/HelpDropDown/HelpDropDown";
import CostCalculationDropDown from "../../ProductMainContent/components/DropDowns/CostCalculationDropDown/CostCalculationDropDown";
import { metric } from "../../../../../utils/yandexMetrics/yandexMetrics";

import styles from "./styles.module.scss";
import BottomSheet from "../../../../../components/UI/Bottomsheet/Bottomsheet";
import ContentFoundCheaper from "../../../../../components/UI/ContentFoundCheaper/ContentFoundCheaper";
import cn from "classnames";
import useSearchParamsHandler from "../../../../../utils/hooks/useSearchParamsHandler";
import { toast } from "react-toastify";
import { formatPrice } from "utils/helpers/formatedPrice";
import { handleYandexEcommerce } from "utils/yandexMetrics/yandexMetricsEcommerce";
import { YandexActionTypeEnum } from "types/YandexActionTypeEnum";
import { IProduct } from "types/IProduct";
import { IOffer } from "types/IOffer";
import { IEcommerceYandex } from "types/IEcommerceYandex";
import { ICurrentOffer } from "types/ICurrentOffer";

interface IMobileMainProductContentProps {
  product: IProduct | null;
  offers: IOffer[];
  isLoading: boolean;
  setIsOpenRedirectModalCart: (isOpen: boolean) => void;
}

const MobileMainProductContent: React.FC<IMobileMainProductContentProps> = (props) => {
  const { cart } = useAppSelector((state) => state.user);
  const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const dispatch = useAppDispatch();
  const { searchParams } = useSearchParamsHandler();
  const { type } = Object.fromEntries(searchParams);
  const [, setSearchParams] = useSearchParams();

  const sortedOffers = useMemo(() => {
    if (!props.product || props.isLoading) return [];
    return getSortedOffersByPriceAndVolume(props.offers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.offers, props.isLoading]);

  const [currentOffer, setCurrentOffer] = useState<ICurrentOffer>({
    id: 0,
    description: "",
    promotionPrice: null,
    price: null,
    percent: null,
    available: false
  });

  const [activeOfferId, setActiveOfferId] = useState(0);

  const onSelectOffer = (options: ICurrentOffer) => {
    setCurrentOffer(options);
    setActiveOfferId(options.id);
    setSearchParams({ type: options.id.toString() });
  };

  const wordsArr = props.product?.name.split(" ");
  const fiveWords = wordsArr?.splice(0, 5);
  const text =
    fiveWords?.length <= wordsArr?.length
      ? fiveWords?.join(" ")
      : fiveWords?.join(" ") + "...";

  // yandex ecommerce 'add'
  const yandexEcommerceAdd = async (id: number) => {
    const selectedOffer = sortedOffers.find((offer) => offer.id === id);

    if (!selectedOffer) {
      return;
    }
    const productVariantString = `${selectedOffer.name}, ID: ${selectedOffer.id}`;
    const ecommerceAddData: IEcommerceYandex = {
      ecommerce: {
        currencyCode: "RUB",
        [YandexActionTypeEnum.add]: {
          products: [
            {
              id: props.product.id?.toString(),
              name: props.product.name,
              category: props.product.category.name,
              brand:
                props.product.brands && !!props.product.brands.length
                  ? props.product.brands[0].brand.name
                  : "",
              price: currentOffer.promotionPrice ?? currentOffer.price,
              quantity: 1,
              variant: productVariantString
            }
          ]
        }
      }
    };

    await handleYandexEcommerce(ecommerceAddData);
  };

  const addProduct = async () => {
    if (!currentOffer.available) {
      toast.info("На данный момент этого товара нет в наличии.");
      return;
    }
    if (process.env.REACT_APP_REDIRECT_CART === "true")
      return props.setIsOpenRedirectModalCart(true);
    if (!props.product) {
      return;
    }

    try {
      const options = {
        id: currentOffer.id,
        count: 1
      };
      dispatch(addProductToCart([options])).unwrap();
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };
  const productInCart = useMemo(
    () => cart.find((e) => e.offer.id === currentOffer.id),
    [cart, currentOffer]
  );

  const addProductAmount = async () => {
    try {
      if (!currentOffer.available) {
        toast.info("На данный момент этого товара нет в наличии.");
        return;
      }
      const options = {
        id: currentOffer.id,
        count: productInCart.count + 1
      };
      dispatch(addProductToCart([options]));
      toast(`Товар "${text}" добавлен в корзину!`);
      metric.addToBasketMetric(currentOffer.promotionPrice, "RUB");

      // yandex ecommerce 'add'
      await yandexEcommerceAdd(options.id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("При добавлении товара в корзину произошла ошибка", err);
    }
  };

  const noImageLink = useMemo(() => {
    return props.product?.category.rewrite_name === "kosmetika"
      ? "https://cdn.parfumart.ru/internal-images/no-photo-kosmetic.svg"
      : "https://cdn.parfumart.ru/internal-images/no-photo-parfume.svg";
  }, [props.product?.category.rewrite_name]);

  const mainSrc =
    !props.isLoading && props.product?.image?.link
      ? props.product?.image?.link
      : noImageLink;

  useEffect(() => {
    if (props.isLoading || sortedOffers.length === 0) return;

    const selectedOffer =
      sortedOffers.find((offer) => offer.id === Number(type)) ||
      sortedOffers[0];

    setCurrentOffer({
      id: selectedOffer?.id || null,
      description: selectedOffer?.name ?? "",
      promotionPrice: selectedOffer?.price_sale ?? selectedOffer?.akcia ?? null,
      price: selectedOffer?.price ?? null,
      percent: selectedOffer?.percent ?? null,
      available: selectedOffer?.available ?? false
    });

    setActiveOfferId(selectedOffer?.id ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedOffers, type]);

  const isCosmetic = props.product?.category.rewrite_name === "kosmetika";

  return (
    <section className={styles.root}>
      <h1 className={styles.seo}>
        {props?.product?.name}
        {isCosmetic
          ? ""
          : props?.offers?.[0]?.offerProductTypes?.[0]?.value || ""}
      </h1>
      <h4 className={styles["vendor-code"]}>
        Артикул: {props?.product?.vendorCode}
      </h4>
      <ProductOffersHeader
        brandRewriteName={props.product?.brands[0].brand.rewrite_name}
        brandName={props.product?.brands[0].brand.name}
        productName={props.product?.name}
        currentOffer={currentOffer}
        isLoading={props.isLoading}
      />
      {sortedOffers.length ? (
        <span className={styles["select-title"]}>
          Выберите вариант товара
        </span>
      ) : (
        <span className={styles["empty-title"]}></span>
      )}
      {(props.isLoading || !loadImg) && (
        <img src={Cosmetics} className={styles["product-image-svg"]} />
      )}
      {!props.isLoading && (
        <img
          itemProp="image"
          src={mainSrc}
          alt={props.product?.product_rewrite_name}
          className={cn(styles["product-image"], {
            [styles["product-image-disabled"]]: props.isLoading || !loadImg
          })}
          onLoad={() => setLoadImg(true)}
        />
      )}
      <MobileOffersList
        productId={props.product?.id}
        activeOfferId={activeOfferId}
        offers={sortedOffers}
        onSelectOffer={onSelectOffer}
      />
      {sortedOffers.length ? (
        <button
          className={styles["button-cheap"]}
          onClick={() => setIsOpenBottomSheet(true)}
        >
          <img
            src={saleTicketIcon}
            alt="sale_ticket-icon"
            className={styles["button-cheap__icon"]}
          />
          <span className={styles["button-cheap__title"]}>
      Нашли дешевле? Сообщите нам!
          </span>
        </button>
      ) : (
        <div className={styles["button-cheap"]} />
      )}
      <button
        className={cn(styles["button-cart"], {
          [styles["button-cart-disabled"]]: props.isLoading,
          [styles["button-cart--no-price"]]: sortedOffers.length === 0
        })}
        onClick={!productInCart ? addProduct : addProductAmount}
      >
        {productInCart ? (
          <span className={styles["button-cart--title"]}>Добавить ещё</span>
        ) : (
          <span className={styles["button-cart--title"]}>
      Добавить в корзину
          </span>
        )}
        {sortedOffers.length > 0 && currentOffer.price && (
          <span className={styles["button-cart__price"]}>
            {!currentOffer.promotionPrice ? (
              `${currentOffer.price} ₽`
            ) : (
              <>
                <span className={styles["button-cart__price--promo"]}>
                  {`${formatPrice(currentOffer.promotionPrice)} ₽ `}
                </span>
                <span className={styles["button-cart__price--main"]}>
                  {`${formatPrice(currentOffer.price)} ₽ `}
                </span>
              </>
            )}
          </span>
        )}
      </button>
      <div className={styles.dropdowns}>
        {process.env.REACT_APP_COMPONENT_ENABLE_DOLYAMI_DROP_DOWN ===
          "true" && (
          <DolyamiDropDown
            price={currentOffer.promotionPrice ?? currentOffer.price}
          />
        )}
        {!!sortedOffers.length && <CostCalculationDropDown
          price={currentOffer.promotionPrice ?? currentOffer.price}
        />}
        <HelpDropDown />
      </div>
      <BottomSheet
        isOpen={isOpenBottomSheet}
        onClose={() => setIsOpenBottomSheet(false)}
      >
        <ContentFoundCheaper currentOfferId={currentOffer.id} onClose={() => setIsOpenBottomSheet(false)} />
      </BottomSheet>
    </section>
  );
};

export default MobileMainProductContent;

import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "store/reduxHooks";

import getPopularBlockItems from "../../../../utils/getPopularBlockItems";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";

import styles from "./styles.module.scss";

const PopularBlock = () => {
  const { deviceType } = useAppSelector((state) => state.user);

  const popularItems = useMemo(() => {
    return getPopularBlockItems(deviceType.isMobile);
  }, [deviceType.isMobile]);

  return (
    <section className={styles.root}>
      <SectionTitle title="Популярные разделы" />
      <div className={styles.list}>
        {popularItems.map((item) => {
          return (
            <div key={item.id} className={styles["popular-wrapper"]}>
              <Link to={item.link} key={item.id} className={styles.popular}>
                <img
                  src={item.src}
                  alt={item.alt}
                  className={styles.popular_img}
                  style={{
                    width: item.width,
                    height: item.height,
                  }}
                  draggable={false}
                />
              </Link>
              <span className={styles.popular_title}>{item.title}</span>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default PopularBlock;
